.pagination {
  position: absolute;
  top:50%;
  left: 0;
  transform: translateY(-50%);
  height: 100px;
  width: 100%;
  display: flex;
  justify-content: space-between;
  pointer-events: none;
  z-index: 2;
  .previous-page-button,.next-page-button{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    width: 60px !important;
    height: 100px !important;
    pointer-events: auto;
    border: 0;
    color: #b5b5b5c7 !important;
    > .anticon{
      font-size: 56px !important;
    }
  }
}


// 반응형
@media (max-width: 600px) {
  .pagination {
    .previous-page-button,.next-page-button{
      > .anticon{
        font-size: 35px !important;
      }
    }
  }
}