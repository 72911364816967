.setting-wrap {
    margin-top: 80px;
    display: flex;
    justify-content: center;
    padding: 20px;
}
@media screen and (max-width: 500px) {
    .setting-wrap {
        padding: 10px 10px 10px 0;
        margin-top: 20px;
    }
}

@media screen and (max-width: 375px) {
    .setting-wrap {
        padding: 10px 10px 10px 0;
        margin-top: 20px;
    }
}
