.viewport {
  position: relative;
  padding: 4vh 0 13vh;
  box-sizing: border-box;
  width: 100vw;
  height: 100vh;
  background-color: #f8fafc;
  overflow: hidden;
  //margin-right: 380px;
  margin-left: 70px;
  display: flex;

  .share-container {
    display: none;

    &.in-sharing {
      display: flex;
      flex-grow: 1;
      justify-content: center;
      align-items: center;
      overflow: hidden;
      margin-left: 10rem;
    }

    .share-container-viewport {
      display: inline-block;
      max-width: 100%;
    }

    .share-canvas {
      width: 100%;
      height: 100%;

      &.hidden {
        display: none;
      }
    }
  }

  .video-container {
    position: relative;
    width: 100%;
    height: 100%;
    display: flex;

    &.in-sharing {
      width: 264px;
      flex-shrink: 0;
      border: none !important;
    }

    .video-canvas {
      width: 100%;
      height: 100%;
    }

    .self-video {
      position: absolute;
      width: 254px;
      height: 143px;
      top: 50px;
      right: 30px;
      z-index: 2;
      display: none;

      &.single-self-video {
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
      }

      &.self-video-show {
        display: block;
      }

    }
  }

  .avatar-list {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    pointer-events: none;
    overflow: hidden;
    padding: 0;
    margin: 0;
  }

  .video-operations {
    position: absolute;
    left: 0;
    bottom: 0;
  }

  .avatar-wrap {
    position: absolute;
    pointer-events: auto;
    list-style: none;
    top: 0;
    left: 0;
  }

  .avatar-wrap-dragging {
    z-index: 10;
  }

  .single-view-avatar {
    top: 0;
    left: 0;
  }

  .self-video-non-sab {
    display: none;
    position: absolute;
  }
}

// .viewport-chat-hidden {
//   position: relative;
//   padding: 4vh 0 13vh;
//   box-sizing: border-box;
//   width: 100vw;
//   height: 100vh;
//   background-color: #f8fafc;
//   overflow: hidden;
//   margin-right: 380px;
//   margin-left: 70px;
//   display: flex;

//   .share-container {
//     display: none;

//     &.in-sharing {
//       display: flex;
//       flex-grow: 1;
//       justify-content: center;
//       align-items: center;
//       overflow: hidden;
//     }

//     .share-container-viewport {
//       display: inline-block;
//       max-width: 100%;
//     }

//     .share-canvas {
//       width: 100%;
//       height: 100%;

//       &.hidden {
//         display: none;
//       }
//     }
//   }

//   .video-container {
//     position: relative;
//     width: 100%;
//     height: 100%;
//     display: flex;

//     &.in-sharing {
//       width: 100%;
//       flex-shrink: 0;
//       border-left: none;
//     }

//     .video-canvas {
//       width: 100%;
//       height: 100%;
//     }

//     .self-video {
//       position: absolute;
//       width: 254px;
//       height: 143px;
//       top: 50px;
//       right: 30px;
//       z-index: 2;
//       display: none;

//       &.single-self-video {
//         width: 100%;
//         height: 100%;
//         top: 0;
//         left: 0;
//       }

//       &.self-video-show {
//         display: block;
//       }

//     }
//   }

//   .avatar-list {
//     position: absolute;
//     top: 0;
//     left: 0;
//     width: 100%;
//     height: 100%;
//     pointer-events: none;
//     overflow: hidden;
//     padding: 0;
//     margin: 0;
//   }

//   .video-operations {
//     position: absolute;
//     left: 0;
//     bottom: 0;
//   }

//   .avatar-wrap {
//     position: absolute;
//     pointer-events: auto;
//     list-style: none;
//     top: 0;
//     left: 0;
//   }

//   .avatar-wrap-dragging {
//     z-index: 10;
//   }

//   .single-view-avatar {
//     top: 0;
//     left: 0;
//   }

//   .self-video-non-sab {
//     display: none;
//     position: absolute;
//   }
// }

.side-icon {
  line-height: 1.5;
  margin-bottom: 105px;

  div {
    padding-bottom: 10px;
    cursor: pointer;

    &:hover {
      color: #6571ff;
    }

    span {
      svg {
        width: 22px;
        height: 22px;
      }
    }
  }
}

.anticon-audio-muted {
  color: #fff !important;
}

[ant-click-animating-without-extra-node='true']::after,
.ant-click-animating-node {
  --antd-wave-shadow-color: none !important
}

/* 사이드바 */
.main-sidebar {
  --tw-translate-x: -100%;
  flex-shrink: 0;
  height: 100%;
  left: 0;
  position: fixed;
  top: 0;
  transform: translate3d(var(--tw-translate-x), var(--tw-translate-y), 0) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  transition-duration: .2s;
  transition-property: transform;
  transition-timing-function: cubic-bezier(.4, 0, .2, 1);
  transition-timing-function: cubic-bezier(.4, 0, 1, 1);
  width: 70px;
  z-index: 60
}

.chat-sidebar {
  --tw-translate-x: -100%;
  flex-shrink: 0;
  height: 100%;
  right: 0;
  position: fixed;
  top: 0;
  transform: translate3d(var(--tw-translate-x), var(--tw-translate-y), 0) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  transition-duration: .2s;
  transition-property: transform;
  transition-timing-function: cubic-bezier(.4, 0, .2, 1);
  transition-timing-function: cubic-bezier(.4, 0, 1, 1);
  width: 380px;
  z-index: 60
}

// 스크롤바
.ts-dropdown-content {
  max-height: 100%;
  overflow-x: hidden;
  overflow-y: auto;
  scroll-behavior: smooth
}

.accordionfix::-webkit-scrollbar {
  display: none;
}

.side-setting-icon {
  position: fixed;
  bottom: 0px;
  background-color: #fff;
  width: 69px;
  padding-bottom: 18px;

  div {
    cursor: pointer;

    &:nth-child(1) {
      stroke: #FFEB3B;     
      margin-bottom: 7px;

      a {
        padding: 0 19px 4px;
        cursor: not-allowed !important;

        &:hover {
          color: inherit !important;
        }
      }

      svg {
        width: 22px;
        height: 22px;
      }
    }

    &:nth-child(2) {
      padding: 0px 0 7px;
      svg {
        width: 22px;
        height: 22px;
      }

      &:hover {
        color: #6571ff;
      }
    }
  }
}

.chat_title {
  display: flex;
  align-items: center;
  padding: 10px 20px;
  border-bottom: 1px solid #e9eef5;
  justify-content: space-between;
  position: sticky;
  top: 0;
  left: 0;
  background: #fff;
  z-index: 99999;
}

//스크롤바
/* 아래의 모든 코드는 영역::코드로 사용 */
.ts-dropdown-content::-webkit-scrollbar {
  width: 5px;
  /* 스크롤바의 너비 */
}

.ts-dropdown-content::-webkit-scrollbar-thumb {
  height: 30%;
  /* 스크롤바의 길이 */
  background: #c5c5c5;
  /* 스크롤바의 색상 */

  border-radius: 10px;
}

.ts-dropdown-content::-webkit-scrollbar-track {
  background: #fff;
  /*스크롤바 뒷 배경 색상*/
}

.flex_style {
  display: flex;
}

.video_style1 {
  width: 70px;
  background-color: #fff;
  color: rgb(63 74 91);
  font-size: 30px;
  border-right: 1px solid #e9eef5;
}

.video_style2 {
  width: 380px;
  color: #3f4a5b;
  border-left: 1px solid #e9eef5;
}

.icon_fs {
  font-size: 13px;
}

.left_modal {
  background-color: #fff;
  width: 300px;
  border-right: 1px solid #e9eef5;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1000;
  height: 100%;
}

.left_modal_title {
  align-items: center;
  justify-content: space-between;
  padding: 15px;
}

.chat-close {
  cursor: pointer;
  padding: 5px;
  font-size: 17px;
  margin-right: 8px;

  &:hover {
    color: #6571ff;
  }
}

.selectedmenu {
  color: #6571ff;
}

// left modal _ hidden
.left_modal_hidden {
  margin-left: 0px !important;
}

.left_modal_hidden10 {
  margin-left: 10px !important;
}

.only_chatinfo_visible {
  width: calc(100% - 330px) !important;
}

.left_modal-alarm {
  // width: 97.4% !important;
}

.left_modal-call {
  margin-left: 7px !important;
  width: 100% !important;
}


// 반응형


@media (max-width: 778px) {
  .viewport {
    margin-right: 0px;
  }

  .video_style2 {
    width: 291px ;
  }
}

@media (max-width: 600px) {
  .side-icon {
    div {
      padding-bottom: 4px;
    }
  }
}


@media screen and (max-width: 820px) {

  .video-container {
    &.in-sharing {
      width: 0px !important;
      flex-shrink: 0;
      border: none !important;
    }
  }

  .viewport {
    .share-container {
      &.in-sharing {
        margin-left: 0rem;
      }
    }
  }
}