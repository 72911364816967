$bg: #65647c;
$shadow: rgba(0, 0, 0, 0.4);
$button-in: #fff;
$button-out: #eee;
.remote-control-panel {
  position: absolute;
  width: 300px;
  height: 240px;
  bottom: 10vh;
  right: 5vw;
  display: flex;
  flex-direction: column;
    align-items: center;
  .control-title{
    font-size: 16px;
    color: #fff;
    font-weight: 700;
  }
  .control-wrap{
    display: flex;
    align-items: center;
    width: 300px;
    height: 200px;
    .zoom-control{
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: space-around;
      width: 50px;
      height: 200px;
      background: #fff;
      border-radius: 16px;
      .ant-btn-icon-only {
        font-size: 42px;
      }
    }
    .pan-control {
      width: 200px;
      height: 200px;
      background: $bg;
      border-radius: 50%;
      padding: 20px;
      -webkit-transform: rotate(45deg);
      -moz-transform: rotate(45deg);
      transform: rotate(45deg);
      box-shadow: inset -12px 0 12px -6px #ddd, inset 12px 0 12px -6px #fff, inset 0 0 0 12px #eee,
        inset 2px 0 4px 12px $shadow, 1px 0 4px rgba(0, 0, 0, 0.8);
      box-sizing: border-box;
      position: relative;
      margin: 10px auto;
     
      a {
        text-decoration: none;
      }
  
       .ant-btn-link {
        display: block;
        width: 46%;
        height: 46%;
        margin: 2%;
        position: relative;
        float: left;
        box-shadow: 1px 0px 3px 1px $shadow, inset 0 0 0 1px #fff;
      }
  
       .ant-btn-link::after {
        content: '';
        display: block;
        width: 50%;
        height: 50%;
        background: $bg;
        position: absolute;
        border-radius: inherit;
      }
  
       .ant-btn-link.turn-up {
        border-radius: 100% 0 0 0;
        background: -webkit-radial-gradient(bottom right, ellipse cover, $button-in 35%, $button-out 75%);
        // background: radial-gradient(bottom right, ellipse cover, $button-in 35%,$button-out 75%);
      }
  
       .ant-btn-link.turn-up::after {
        bottom: 0;
        right: 0;
        box-shadow: inset 2px 1px 2px 0 $shadow, 10px 10px 0 10px $bg;
        -webkit-transform: skew(-3deg, -3deg) scale(0.96);
        -moz-transform: skew(-3deg, -3deg) scale(0.96);
        transform: skew(-3deg, -3deg) scale(0.96);
      }
  
       .ant-btn-link.turn-right {
        border-radius: 0 100% 0 0;
        background: -webkit-radial-gradient(bottom left, ellipse cover, $button-in 35%, $button-out 75%);
      }
  
       .ant-btn-link.turn-right::after {
        bottom: 0;
        left: 0;
        box-shadow: inset -2px 3px 2px -2px $shadow, -10px 10px 0 10px $bg;
        -webkit-transform: skew(3deg, 3deg) scale(0.96);
        -moz-transform: skew(3deg, 3deg) scale(0.96);
        transform: skew(3deg, 3deg) scale(0.96);
      }
  
       .ant-btn-link.turn-left {
        border-radius: 0 0 0 100%;
        background: -webkit-radial-gradient(top right, ellipse cover, $button-in 35%, $button-out 75%);
        //  background: radial-gradient(top right, ellipse cover, $button-in 35%,$button-out 75%);
      }
  
       .ant-btn-link.turn-left::after {
        top: 0;
        right: 0;
        box-shadow: inset 2px -1px 2px 0 $shadow, 10px -10px 0 10px $bg;
        -webkit-transform: skew(3deg, 3deg) scale(0.96);
        -moz-transform: skew(3deg, 3deg) scale(0.96);
        transform: skew(3deg, 3deg) scale(0.96);
      }
  
       .ant-btn-link.turn-down {
        border-radius: 0 0 100% 0;
        background: -webkit-radial-gradient(top left, ellipse cover, $button-in 35%, $button-out 75%);
        // background: radial-gradient(top left, ellipse cover, $button-in 35%,$button-out 75%);
      }
  
       .ant-btn-link.turn-down::after {
        top: 0;
        left: 0;
        box-shadow: inset -2px -3px 2px -2px $shadow, -10px -10px 0 10px $bg;
        -webkit-transform: skew(-3deg, -3deg) scale(0.96);
        -moz-transform: skew(-3deg, -3deg) scale(0.96);
        transform: skew(-3deg, -3deg) scale(0.96);
      }
      .ant-btn-link.center-button {
        display: block;
        height: 38%;
        width: 38%;
        position: absolute;
        top: 31%;
        left: 31%;
        background: #fff;
        border-radius: 50%;
        box-shadow: 1px 0 4px rgba(0, 0, 0, 0.8);
        float: none;
        margin: 0;
      }
      .ant-btn-link.center-button::after {
        content: none;
        display: none;
      }
  
      .anticon {
        transform: rotate(-45deg);
        position: absolute;
        font-size: 42px;
        top: 21%;
        left: 23%;
        text-shadow: 1px 1px 4px #fff, 0px 0px 0px rgba(0, 0, 0, 0.5);
        color: rgba(0, 0, 0, 0.4);
        &:hover{
          color: rgba(0, 0, 0, 0.8);
        }
      }
  
      .top .anticon {
        top: 15%;
        left: 18%;
      }
  
      .left .anticon {
        top: 20%;
        left: 16%;
      }
  
      .right .anticon {
        top: 15%;
        left: 30%;
      }
  
      .bottom .anticon {
        top: 20%;
        left: 30%;
      }
    }
  }
  
}
.remote-control-dropdown{
  position: absolute;
  top: 3vh;
  left: 3vw;
  width: 30px !important;
  height: 30px !important;
  border: none;
  > *{
    font-size: 18px !important;
    color: #439a97;
  }
}
