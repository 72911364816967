.video-footer {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 55px;
  width: 100%;
  background-color: #fff;
  border-top: 1px solid #e9eef5;
  div {
    &:first-child {
      button {
        &:first-child {
          svg {
            fill: rgb(63 74 91);
            width: 23px;
            height: 23px;
            &:hover {
              fill: #6571ff;
            }
          }
        }
      }
    }
    &:nth-child(2) {
      button {
        &:first-child {
          svg {
            fill: rgb(63 74 91);
            width: 23px;
            height: 23px;
            &:hover {
              fill: #6571ff;
            }
          }
        }
      }
    }
    &:last-child{
      button {
        padding: 6px 0;
        &:first-child {
          svg {
            fill: rgb(63 74 91);
            width: 23px;
            height: 23px;
            &:hover {
              fill: #6571ff;
            }
          }
        }
        &:nth-child(2) {
          svg {
            fill: rgb(63 74 91);
            width: 14px;
            height: 14px;
            &:hover {
              fill: #6571ff;
            }
          }
        }
      }
    }
    button {
      &:first-child {
        svg {
          fill: rgb(63 74 91);
          width: 23px;
          height: 23px;
          &:hover {
            fill: #6571ff;
          }
        }
      }
      &:nth-child(2) {
        svg {
          fill: rgb(63 74 91);
          width: 14px;
          height: 14px;
          &:hover {
            fill: #6571ff;
          }
        }
      }
    }
  }
  >.ant-btn {
    span {
      &:first-child {
        svg {
          width: 23px;
          height: 23px;
          fill: rgb(63 74 91);
          &:hover{
            fill: #6571ff;
          }
        }
      }
      &:nth-child(2) {
        svg {
          fill: rgb(63 74 91);
          width: 14px;
          height: 14px;
          &:hover{
            fill: #6571ff;
          }
        }
      }
    }
    >&:nth-child(2){
      span {
          svg {
            width: 30px !important;
            height: 30px !important;
            &:hover{
              fill: #6571ff;
            }
        }
      }
    }
  }
}
.vc-button {
  font-size: 30px;
  color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right:min(30px,1.5vw);
  width: 40px !important;
  height: 45px !important;
  border: none !important;
  padding-left: 10px !important;
  cursor: pointer;
  > *{
    font-size: 28px !important;
  }
  &:hover {
    color: #6571ff;
  }
}
.vc-dropdown-button {
  width: 70px !important;
  height: 45px !important;
  display: flex;
  align-items: center;
  justify-content: center;
  > .ant-btn {
    font-size: 26px;
    display: flex;
    // height: 60px !important;
    align-items: center;
    border-width: 0 !important;
    padding: 0;
    margin-right: 5px;

  }
  > .ant-btn[disabled]{
    background: transparent;
    color: #999;
  }
  > .ant-dropdown-trigger {
    width: 20px !important;
    margin-right: 0;
    margin-left: 3px;
  }
}
.vc-dropdown-menu {
  border: 1px solid #ccc;
  background: #fff !important;
  .ant-dropdown-menu-item {
    padding: 10px 30px;
    color: #000;
    position: relative;
    .anticon-check {
      position: absolute;
      left: -4px;
      top: 9px;
    }
  }
  .ant-dropdown-menu-item-group-title {
    color: #000;
    font-weight: 700;
  }
}

.micBtnSvg {
  .ant-btn {
    background-color: #fff;
    justify-content: center;
    box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 6px -1px, rgba(0, 0, 0, 0.06) 0px 2px 4px -1px;
    &:hover {
      color: #ffffff;
      background-color: #f0f0f0;
    }
    &:first-child {
      span {
        line-height: 0;
        svg {
          width: 24px;
          height: 24px;
        }
      }
    }
    &:nth-child(2) {
      span {
        svg {
          width: 14px;
          height: 14px;
        }
      }
    }
  }
}

.cameraBtnSvg {
  .ant-btn {
    background-color: #fff;
    justify-content: center;
    box-shadow: rgba(0, 0, 0, 0.1) 0px 5px 6px -1px, rgba(0, 0, 0, 0.06) 0px 2px 4px -1px;
    &:hover {
      color: #ffffff;
      background-color: #f0f0f0;
      fill: #6571ff !important;
    }
    &:first-child {
      span {
        line-height: 0;
        svg {
          width: 24px;
          height: 24px;
        }
      }
    }
    &:nth-child(2) {
      span {
        svg {
          width: 14px;
          height: 14px;
        }
      }
    }
  }
}

.previewClickBtn{
  width: 96px !important;
}

.ant-btn.ant-btn-background-ghost {
  color: #6571ff !important;
}

// .ant-dropdown-menu-item:hover {
//   color: #000 !important;
//   background: #eee !important;
// }

:where(.css-dev-only-do-not-override-1sn0ohr).ant-dropdown .ant-dropdown-menu .ant-dropdown-menu-item:hover, :where(.css-dev-only-do-not-override-1sn0ohr).ant-dropdown-menu-submenu .ant-dropdown-menu .ant-dropdown-menu-item:hover, :where(.css-dev-only-do-not-override-1sn0ohr).ant-dropdown .ant-dropdown-menu .ant-dropdown-menu-submenu-title:hover, :where(.css-dev-only-do-not-override-1sn0ohr).ant-dropdown-menu-submenu .ant-dropdown-menu .ant-dropdown-menu-submenu-title:hover, :where(.css-dev-only-do-not-override-1sn0ohr).ant-dropdown .ant-dropdown-menu .ant-dropdown-menu-item-active, :where(.css-dev-only-do-not-override-1sn0ohr).ant-dropdown-menu-submenu .ant-dropdown-menu .ant-dropdown-menu-item-active, :where(.css-dev-only-do-not-override-1sn0ohr).ant-dropdown .ant-dropdown-menu .ant-dropdown-menu-submenu-title-active, :where(.css-dev-only-do-not-override-1sn0ohr).ant-dropdown-menu-submenu .ant-dropdown-menu .ant-dropdown-menu-submenu-title-active {
   background-color: inherit !important; 
}

.ant-dropdown-menu {
  box-shadow: none !important;
}


//tooltip
.ant-tooltip-inner {
  background-color: #fff !important;
  color: #7b85a1 !important;
  box-shadow: none !important;
  border: 1px solid #7b85a1;
}

.ant-tooltip-arrow-content {
  --antd-arrow-background-color: none !important;
  box-shadow: none !important;
}

.ant-tooltip-placement-top, .ant-tooltip-placement-topLeft, .ant-tooltip-placement-topRight {
  padding-bottom: 7px;
}
.full-screen-icon{
  width: 28px;
  height: 28px;
  background: #ffffff;
  cursor: pointer;
  position: absolute;
  bottom: 60px;
  right: 4px;
  border: 1px solid rgb(233, 238, 245);
  border-radius: 3px;
  padding-left: 2px;
  svg{
    color: #505050;
    width: 16px;
    height: 15px;
    margin: 5px 0 0 0;
    &:hover{
      color: #6571ff;
    }
  }
}

// 반응형
@media (max-width: 1377px) {
  .vc-button {
    padding-left: 5px !important;
  }
}

@media (max-width: 1100px) {
  .vc-button {
    padding-left: 0px !important;
  }
}

@media (max-width: 600px) {
  .video-footer {
    div {
      &:first-child {
        button {
          &:first-child {
            svg {
              width: 19px;
              height: 19px;
            }
          }
        }
      }
      &:nth-child(2) {
        button {
          &:first-child {
            svg {
              width: 19px;
              height: 19px;
            }
          }
        }
      }
      &:last-child{
        button {
          &:first-child {
            svg {
              width: 19px;
              height: 19px;
            }
          }
        }
      }
      button {
        &:first-child {
          svg {
            width: 19px;
            height: 19px;
          }
        }
      }
    }
    >.ant-btn {
      span {
        &:first-child {
          svg {
            width: 19px;
            height: 19px;
          }
        }
      }
    }
  }

  .vc-dropdown-button {
    width: 54px !important;
    height: 40px !important;
  }
  
}

@media (max-width: 300px) {
  .video-footer {
    div {
      &:first-child {
        button {
          &:first-child {
            svg {
              fill: rgb(63 74 91);
              width: 17px;
              height: 17px;
              &:hover {
                fill: #6571ff;
              }
            }
          }
        }
      }
      &:nth-child(2) {
        button {
          &:first-child {
            svg {
              fill: rgb(63 74 91);
              width: 17px;
              height: 17px;
              &:hover {
                fill: #6571ff;
              }
            }
          }
        }
      }
      &:last-child{
        button {
          padding: 6px 0;
          &:first-child {
            svg {
              fill: rgb(63 74 91);
              width: 17px;
              height: 17px;
              &:hover {
                fill: #6571ff;
              }
            }
          }
          &:nth-child(2) {
            svg {
              fill: rgb(63 74 91);
              width: 12px;
              height: 12px;
              &:hover {
                fill: #6571ff;
              }
            }
          }
        }
      }
      button {
        &:first-child {
          svg {
            fill: rgb(63 74 91);
            width: 17px;
            height: 17px;
            &:hover {
              fill: #6571ff;
            }
          }
        }
        &:nth-child(2) {
          svg {
            fill: rgb(63 74 91);
            width: 12px;
            height: 12px;
            &:hover {
              fill: #6571ff;
            }
          }
        }
      }
    }
    >.ant-btn {
      span {
        &:first-child {
          svg {
            width: 17px;
            height: 17px;
            fill: rgb(63 74 91);
            &:hover{
              fill: #6571ff;
            }
          }
        }
        &:nth-child(2) {
          svg {
            fill: rgb(63 74 91);
            width: 12px;
            height: 12px;
            &:hover{
              fill: #6571ff;
            }
          }
        }
      }
      >&:nth-child(2){
        span {
            svg {
              width: 17px !important;
              height: 17px !important;
              &:hover{
                fill: #6571ff;
              }
          }
        }
      }
    }
  }


  .ant-btn-circle.ant-btn-lg {
    min-width: 25px;
  }

  .vc-dropdown-button {
    width: 39px !important;
  }

  .vc-button {
    width: 35px !important;
}

  .video-footer div:last-child button {
    padding-left: 2px;
  }
}