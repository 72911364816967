// 모바일
.ant-list-items {
  margin: 0;
  padding: 0;
  list-style: none;
}
.ant-list-item {
  display: flex;
  align-items: center !important;
  justify-content: space-between;
  padding: 12px 0;
  color: rgba(0,0,0,.88);
}
.ant-list-item-meta{
  display: flex;
  flex: 1;
  align-items: flex-start;
  max-width: 100%;
}

.ant-list-item-meta-avatar{
  margin-inline-end: 16px;
}
.ant-list-item-meta-content{
  flex: 1 0;
  width: 0;
  color: rgba(0,0,0,.88);
  text-align: left;
}
.ant-list-item-meta-title{
  margin: 0 0 4px;
  color: rgba(0,0,0,.88);
  font-size: 14px;
  line-height: 1.5714285714285714;
}
.ant-list-item-meta-description{
  color: rgba(0,0,0,.45);
  font-size: 12px;
  line-height: 1.5714285714285714;
}
.ant-list-item-action{
  flex: 0 0 auto;
  margin-inline-start: 48px;
  padding: 0;
  font-size: 0;
  list-style: none;
}


//파일 헤더
.header-nav {
  background-color: #fff;
  border-bottom: 1px solid #e9eef5;
  width: 100%;
  height: 62px;
  position: absolute;
  top: 0;
  right: 0;
  display: flex;
  justify-content: space-between;
  align-items: center;

  button {
    color: #3f4a5b;
    border: none;

    &:hover {
      border: none;
    }
  }
}

.file-tree-content {
  .header-nav {
    padding-right: 12px;

    .header-name {
      h3 {
        margin: 0 0 0 30px;
      }
    }
  }
}

.file-tree-hidden {
  .header-nav {
    padding-right: 16px;

    .header-name {
      h3 {
        margin: 0 0 0 36px;
      }
    }
  }
}

.file-tree-hidden {
  .alarm-header-nav {
    // padding-right: 30px;

    .alarm-header-name {
      h3 {
        margin: 0 0 0 36px;
      }
    }
  }
}

.file-tree-content {
  .call-history-table {
    width: 100%;
    border: 1px solid #e9eef5;
    margin-top: 10px;

    thead {
      tr {
        th {
          background: #e9eef5;

          &:first-child {
            width: 30%;
          }
        }
      }
    }

    tbody {
      tr {
        td {
          &:first-child {
            width: 30%;
            padding-left: 10px;
          }

          &:nth-child(3) {
            text-align: center;
          }

          &:nth-child(4) {
            text-align: center;
          }
        }
      }
    }
  }
}

.filesharing-svg-wrap {
  svg {
    color: #3f4a5b;
  }
}

.file-downicon {
  font-size: 15px;

  svg {
    &:hover {
      color: #6571ff;
    }
  }
}


.tree-fill-wrap {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

//파일 테이블
.file-tree-content {
  margin-left: 369px;
  padding-top: 10px;
  position: relative;
}
.file-tree-hidden {
  margin-left: 70px ;
}

.file-head-icon {
  button {
    border: none;

    &:hover {
      border: none;
    }
  }
}



.file-table {
  border: 1px solid #e9eef5;
  margin-top: 10px;
  width: 100%;
  text-align: left;
  // margin-right: 20px;

  thead {
    th {
      padding: 10px 10px 10px 15px !important;
      background: #e9eef5;

      &:first-child {
        width: 20% !important;
      }

      &:nth-child(2) {
        width: 25% !important;
      }

      &:nth-child(3) {
        width: 20% !important;
      }

      &:nth-child(4) {
        width: 20% !important;
      }

      &:nth-child(5) {
        width: 10% !important;
      }
    }



  }

  tbody {
    td {
      padding: 10px 10px 10px 15px !important;
      text-align: left;

      &:first-child {
        div {
          align-items: center;
          display: flex;

          svg {
            color: #eab308;
          }

          span {
            margin-left: 0.5rem;
          }
        }
      }
    }
  }
}


.file-more-btn {
  border: none;

  &:hover {
    border: none;
  }
}

.file-member-icon {
  width: 28px;
  height: 28px;
  text-align: center;
  line-height: 24px;
}

.file-table-wrap {
  margin-top: 80px;
  padding: 0 28px 20px;
}

@media screen and (max-width: 600px) {

  //파일 모바일
  .file-tree-hidden {
    .file-table-wrap {
      padding: 0 0 20px 10px;
      overflow-x: scroll;

      .file-table {
        width: 600px;
      }
    }

    .header-nav {
      padding-right: 16px;

      .header-name {
        h3 {
          margin: 0 0 0 20px;
        }
      }
    }
  }

  .file-search {
    width: 600px;
  }
  .header-nav{
    width: 97%;
  }
}

@media screen and (max-width: 610px) {

  //연락처 왼쪽모달 있을때
  .file-tree-content {
    .call-header-btn {
      display: none;
    }
  }

  .file-tree-hidden {
    .call-header-btn {
      display: block;
    }
  }

  .file-tree-content {
    .call-table-wrap {
      .call-history-table {
        width: 500px;
      }
    }
  }
  
  //파일 왼쪽모달 있을때
  .file-tree-content {
    .file-header-btn {
      display: none;
    }
  }

  .file-tree-hidden {
    .file-header-btn {
      display: block;
    }
  }

  .file-tree-content {
    .file-table-wrap {
      overflow-x: scroll;

      .file-table {
        width: 500px;
      }
    }
  }

  .file-search {
    width: 500px;
  }
}

@media screen and (max-width: 500px) {
  //통화 본문 테이블
  .file-tree-hidden {
    .call-table-wrap {
      padding: 0 0 20px 10px;

      .file-table {
        width: 500px;
      }
    }

    .call-search-wd {
      width: 500px;
    }
  }
}
.file-create-modal{
  .ant-modal-content{
    width: 450px;
    height: 410px;
  }
}
@media screen and (max-width: 500px) {
  .file-create-modal{
    .ant-modal-content{
      width: 80%;
    }
  }
}