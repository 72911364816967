//연락처 헤더
.contact-header-nav {
  background-color: #fff;
  border-bottom: 1px solid #e9eef5;
  width: 100%;
  height: 62px;
  position: absolute;
  top: 0;
  right: 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-right: 12px;

  button {
    color: #3f4a5b;
    border: none;

    &:hover {
      border: none;
    }
  }
}

.contact-tree-content {
  .contact-header-nav {
    // padding-right: 25px;

    .contact-header-name {
      h3 {
        margin: 0 0 0 28px;
      }
    }
  }
}

.contact-tree-hidden {
  .contact-header-nav {
    // padding-right: 30px;

    .contact-header-name {
      h3 {
        margin: 0 0 0 33px;
      }
    }
  }
}

//연락처
.contact-tree-content {
  margin-left: 370px;
  padding-top: 10px;
}

.contact-tree-hidden {
  margin-left: 70px;
  padding-top: 10px;
}

.contact-table-pd {
  padding-left: 1rem;
  padding-right: 1rem;
}

.name-circle-size {
  width: 35px;
  height: 35px;
}

.contact-img {
  width: 25px;
  height: 25px;
  margin-right: 7px;
}

.contact-wrap {
  width: calc(100% - 30px);
  margin-top: 50px;
}


//연락처 테이블 스크롤
.contact-table {
  border: 1px solid #e9eef5;
  width: 100%;
  margin-top: 10px;


  thead {
    tr {
      th {
        background: #e9eef5;

        &:first-child {
          width: 5% !important;
        }

        &:last-child {
          width: 7%;
        }
      }
    }
  }

  tbody {
    tr {
      td {
        &:first-child {
          width: 5%;
          text-align: center;
        }

        &:last-child {
          width: 7%;
          text-align: center;
        }
      }

    }
  }
}

.contact-margin5 {
  margin-right: 7px;
  font-size: 15px;
}

.contact_number {
  color: rgb(138, 138, 138);
}

.contact-search {
  width: 85%;
}

@media screen and (max-width: 1055px) {
  .contact-tree-content {
    .contact-search {
      width: 80%;
    }
  }

}

@media screen and (max-width: 870px) {
  .contact-tree-content {
    .contact-search {
      width: 70%;
    }
  }
}

@media screen and (max-width: 712px) {
  .contact-tree-content {
    .contact-search {
      width: 60%;
    }
  }
}

@media screen and (max-width: 630px) {
  .contact-tree-content {
    .contact-search {
      width: 50%;
    }
  }

  .contact-header-nav {
    padding-right: 16px;
  }
}

.contact-scroll {
  overflow-x: scroll;
}

.contact-scroll::-webkit-scrollbar-track {
  border-radius: 2px;
}

.contact-scroll::-webkit-scrollbar {
  width: 4px;
  height: 4px;
  background-color: #F7F7F7;
}

.contact-scroll::-webkit-scrollbar-thumb {
  border-radius: 10px;
  background-color: #BFBFBF;
}

.contact-table-btn {
  display: flex;
  // justify-content: flex-end;
  width: 100%;
}

@media screen and (max-width: 1120px) {

  .contact-table {
    // width: 670px;
  }

  .contact-table-btn {
    // width: 670px;
  }
}

// table{
//   width: 350px;
// }
.contact-video {
  width: 17.5px;
  height: 18.5px;
  color: #2b3441;

  &:hover {
    color: #6571ff;
  }
}

.contact-call {
  width: 19px;
  height: 19px;
  color: #11151b;

  &:hover {
    color: #6571ff;
  }
}

//페이지네이션
.contact-pagination {
  margin-top: 50px;
  font-size: 14px;
  height: 40px;
  width: 100%;
  display: flex;
  justify-content: center;
  // position: absolute;
  bottom: 0;
  left: 0;
  list-style: none;
  cursor: pointer;
  background-color: #fff;
}

@media screen and (max-width: 500px) {

  //연락처 모바일
  .contact-table-pd {
    padding: 0;
  }

  .contact-tree-hidden {
    margin-left: 45px;
    padding-left: 18px;
  }

  .contact-table-wrap {
    padding: 0 20px 20px;
  }

  .contact-tree-content {
    .contact-header-nav {
      .contact-header-name {
        h3 {
          margin: 0 0 0 46px;
          width: 53px;
        }
      }
    }
  }

  .contact-wrap {
    width: calc(100% - 25px);
    overflow-x: scroll;
  }

  .contact-table-wd {
    width: 500px;
  }
}

.contact-create-modal {
  .ant-modal-content {
    width: 450px;
    height: 384px
  }
}

@media screen and (max-width: 500px) {
  .contact-create-modal {
    .ant-modal-content {
      width: 80%;
    }
  }
}

@media screen and (max-width: 326px) {
  .contact-create-modal {
    .ant-modal-content {
      width: 80%;
    }
  }
}

//연락처 테이블 모바일
.contact-table-mb {
  width: 100%;

  thead {
    tr {
      th {
        background: #e9eef5;

        &:first-child {
          width: 5%;
          text-align: center;
          padding: 8px 2px;
        }

        &:nth-child(2) {
          width: 20% !important;
        }

        &:nth-child(3) {
          width: 35% !important;
        }

        &:last-child {
          width: 40%;
          text-align: center;
        }
      }
    }
  }

  tbody {
    tr {
      td {
        &:first-child {
          width: 5%;
          text-align: center;
          padding: 8px 4px;
        }

        &:nth-child(2) {
          width: 20% !important;
        }

        &:nth-child(3) {
          width: 35% !important;
        }

        &:last-child {
          width: 40%;
          text-align: center;
        }
      }

    }
  }
}

.contact-table-btn-mb {
  display: flex;
  justify-content: flex-end;
  width: 100%;
}

.contact-pagination-mb {
  font-size: 14px;
  height: 40px;
  width: 100%;
  display: flex;
  justify-content: center;
  position: absolute;
  bottom: -25px;
  left: 0;
  list-style: none;
  background-color: #fff;
}

.contact-table-mb {
  font-size: 13px;
}

.mb-table-height {
  height: "calc(100vh - 124px)"
}


.contact-info-sidebar {
  height: 100vh;
  background: #fff;
  display: flex;
  flex-direction: column;
  --tw-translate-x: -100%;
  flex-shrink: 0;
  right: 0;
  position: fixed;
  top: 0;
  transform: translate3d(var(--tw-translate-x), var(--tw-translate-y), 0) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  transition-duration: 0.2s;
  transition-property: transform;
  width: 330px;
  z-index: 1001;
  border-left: 1px solid #e9eef5;
}

@media screen and (max-width: 500px) {
  .userinfo-modal {
    .ant-modal-content {
      width: 300px;
      height: 400px;
    }
  }
}

@media screen and (max-width: 326px) {
  .userinfo-modal {
    .ant-modal-content {
      width: 270px;
      height: 400px;
    }
  }
}

@media screen and (max-width: 290px) {
  .userinfo-modal {
    .ant-modal-content {
      width: 245px;
      height: 400px;
    }
  }

  .contact-info-sidebar {
    width: 100%;
  }

  .contact-text-mb {
    flex-wrap: wrap;
  }

  .contact-margin5 {
    margin-right: 5px;
    font-size: 14px;
  }

  .contact_number {
    font-size: 13px;
    margin-right: 2px;
  }
}

.call-list-mb {
  .ant-list-item {
    .ant-list-item-meta {
      align-items: center;

      h4 {
        margin-bottom: 0;
      }
    }
  }
}

.list-start-name {
  margin: 10px;
  text-align: left;
  font-size: 16px;
  color: rgba(0, 0, 0, 0.5);
}

.contact-list-mb {
  display: flex;
  flex: 1 1;
  align-items: center;
  max-width: 100%;
}

.contact-list-avata-mb {
  margin-inline-end: 10px;
}


@media screen and (max-width: 600px) {
  .contact-info-sidebar {
    width: calc(100% - 70px) !important;
  }
}

@media screen and (max-width: 414px) {
  .contact-info-sidebar {
    width: calc(100% - 60px) !important;
  }
}

.contact-mb-check {
  margin-right: 13px;
}

.contact-mb-video-icon {
  margin-right: 7px;
}

.contact-mb-call-icon {
  width: 19px;
}

@media screen and (max-width: 290px) {
  .contact-mb-check {
    margin-right: 5px;
  }

  a {
    margin: 0;
  }

  .contact-mb-video-icon {
    margin-right: 4px;

    svg {
      width: 15px;
    }
  }

  .contact-mb-call-icon {
    svg {
      width: 15px;
    }
  }

  .contact-list-avata-mb {
    display: none;
  }
}

.contact-list-item-mb {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 9px 0;
  color: rgba(0, 0, 0, 0.88);
  border-bottom: 1px solid rgba(0, 0, 0, 0.06);
}
