table {
  border-collapse: collapse !important;
  font-size: 14px;
}

a {
  text-decoration: none;
}

.form-input {
  height: 16px !important;
}

.p-0 {
  padding: 0 !important;
}

.p-0\.5 {
  padding: .125rem
}

.p-1 {
  padding: .25rem
}

.p-1\.5 {
  padding: .375rem
}

.p-2 {
  padding: .5rem
}

.p-2\.5 {
  padding: .625rem
}

.p-3 {
  padding: .75rem
}

.p-3\.5 {
  padding: .875rem
}

.p-4 {
  padding: 1rem
}

.p-4\.5 {
  padding: 1.125rem
}

.p-5 {
  padding: 1.25rem
}

.p-6 {
  padding: 1.5rem
}

.p-\[3px\] {
  padding: 3px
}

.p-px {
  padding: 1px
}

.px-0 {
  padding-left: 0;
  padding-right: 0
}

.px-0\.5 {
  padding-left: .125rem;
  padding-right: .125rem
}

.px-1 {
  padding-left: .25rem;
  padding-right: .25rem
}

.px-1\.5 {
  padding-left: .375rem;
  padding-right: .375rem
}

.px-2 {
  padding-left: .5rem;
  padding-right: .5rem
}

.px-2\.5 {
  padding-left: .625rem;
  padding-right: .625rem
}

.px-3 {
  padding-left: .75rem;
  padding-right: .75rem
}

.px-3\.5 {
  padding-left: .875rem;
  padding-right: .875rem
}

.px-4 {
  padding-left: 1rem;
  padding-right: 1.5rem
}

.px-5 {
  padding-left: 1.25rem;
  padding-right: 1.25rem
}

.px-6 {
  padding-left: 1.5rem;
  padding-right: 1.5rem
}

.px-8 {
  padding-left: 2rem;
  padding-right: 2rem
}

.px-9 {
  padding-left: 2.25rem;
  padding-right: 2.25rem
}

.px-\[calc\(var\(--margin-x\)-\.25rem\)\] {
  padding-left: calc(var(--margin-x) - .25rem);
  padding-right: calc(var(--margin-x) - .25rem)
}

.px-\[calc\(var\(--margin-x\)-\.5rem\)\] {
  padding-left: calc(var(--margin-x) - .5rem);
  padding-right: calc(var(--margin-x) - .5rem)
}

.px-\[var\(--margin-x\)\] {
  padding-left: var(--margin-x);
  padding-right: var(--margin-x)
}

.py-0 {
  padding-bottom: 0;
  padding-top: 0
}

.py-0\.5 {
  padding-bottom: .125rem;
  padding-top: .125rem
}

.py-1 {
  padding-bottom: .25rem;
  padding-top: .25rem
}

.py-1\.5 {
  padding-bottom: .375rem;
  padding-top: .375rem
}

.py-10 {
  padding-bottom: 2.5rem;
  padding-top: 2.5rem
}

.py-12 {
  padding-bottom: 3rem;
  padding-top: 3rem
}

.py-2 {
  padding-bottom: .5rem;
  padding-top: .5rem
}

.py-2\.5 {
  padding-bottom: .625rem;
  padding-top: .625rem
}

.py-3 {
  padding-bottom: .75rem;
  padding-top: .75rem
}

.py-4 {
  padding-bottom: 1rem;
  padding-top: 1rem
}

.py-5 {
  padding-bottom: 1.25rem;
  padding-top: 1.25rem
}

.py-6 {
  padding-bottom: 1.5rem;
  padding-top: 1.5rem
}

.py-8 {
  padding-bottom: 2rem;
  padding-top: 2rem
}

.pb-0 {
  padding-bottom: 0
}

.pb-0\.5 {
  padding-bottom: .125rem
}

.pb-1 {
  padding-bottom: .25rem
}

.pb-12 {
  padding-bottom: 3rem
}

.pb-2 {
  padding-bottom: .5rem
}

.pb-3 {
  padding-bottom: .75rem
}

.pb-4 {
  padding-bottom: 1rem
}

.pb-5 {
  padding-bottom: 1.25rem
}

.pb-6 {
  padding-bottom: 1.5rem
}

.pb-8 {
  padding-bottom: 2rem
}

.pb-20 {
  padding-bottom: 1.5px;
}

.pl-10 {
  padding-left: 1.6px;
}

.pl-4 {
  padding-left: 1rem
}

.pl-9 {
  padding-left: 2.25rem
}

.pl-\[var\(--main-sidebar-width\)\] {
  padding-left: var(--main-sidebar-width)
}

.pl-\[var\(--margin-x\)\] {
  padding-left: var(--margin-x)
}

.pr-1 {
  padding-right: .25rem
}

.pr-12 {
  padding-right: 3rem
}

.pr-2 {
  padding-right: .5rem
}

.pr-3 {
  padding-right: .75rem
}

.pr-6 {
  padding-right: 1.5rem
}

.pr-8 {
  padding-right: 2rem
}

.pr-9 {
  padding-right: 2.25rem
}

.pt-0 {
  padding-top: 0
}

.pt-1 {
  padding-top: .25rem
}

.pt-1\.5 {
  padding-top: .375rem
}

.pt-10 {
  padding-top: 2.5rem
}

.pt-12 {
  padding-top: 3rem
}

.pt-14 {
  padding-top: 3.5rem
}

.pt-2 {
  padding-top: .5rem
}

.pt-3 {
  padding-top: .75rem
}

.pt-4 {
  padding-top: 1rem
}

.pt-5 {
  padding-top: 1.25rem
}

.pt-6 {
  padding-top: 1.5rem
}

.h-1 {
  height: .25rem
}

.h-1\.5 {
  height: .375rem
}

.h-10 {
  height: 2.5rem
}

.h-11 {
  height: 2.75rem
}

.h-12 {
  height: 3rem
}

.h-14 {
  height: 3.5rem
}

.h-16 {
  height: 4rem
}

.h-18 {
  height: 4.5rem
}

.h-2 {
  height: .5rem
}

.h-2\.5 {
  height: .625rem
}

.h-20 {
  height: 5rem
}

.h-24 {
  height: 6rem
}

.h-28 {
  height: 7rem
}

.h-3 {
  height: .75rem
}

.h-3\.5 {
  height: .875rem
}

.h-36 {
  height: 9rem
}

.h-4 {
  height: 1rem
}

.h-4\.5 {
  height: 1.125rem
}

.h-40 {
  height: 10rem
}

.h-44 {
  height: 11rem
}

.h-48 {
  height: 12rem
}

.h-5 {
  height: 1.25rem
}

.h-5\.5 {
  height: 1.375rem
}

.h-56 {
  height: 14rem
}

.h-6 {
  height: 1.5rem
}

.h-64 {
  height: 16rem
}

.h-7 {
  height: 1.75rem
}

.h-72 {
  height: 18rem
}

.h-8 {
  height: 2rem
}

.h-80 {
  height: 20rem
}

.h-9 {
  height: 2.25rem
}

.h-\[60px\] {
  height: 60px
}

.h-\[61px\] {
  height: 61px
}

.h-\[calc\(100\%-2\.5rem\)\] {
  height: calc(100% - 2.5rem)
}

.h-\[calc\(100\%-4\.5rem\)\] {
  height: calc(100% - 4.5rem)
}

.h-\[calc\(100vh-8\.5rem\)\] {
  height: calc(100vh - 8.5rem)
}

.h-full {
  height: 100%
}

.h-px {
  height: 1px
}

.max-h-96 {
  max-height: 24rem
}

.max-h-\[calc\(100vh-120px\)\] {
  max-height: calc(100vh - 120px)
}

.max-h-\[calc\(100vh-6rem\)\] {
  max-height: calc(100vh - 6rem)
}

.max-h-full {
  max-height: 100%
}

.\!w-10\/12 {
  width: 83.333333% !important
}

.w-0 {
  width: 0
}

.w-1 {
  width: .25rem
}

.w-1\.5 {
  width: .375rem
}

.w-1\/12 {
  width: 8.333333%
}

.w-1\/2 {
  width: 50%
}

.w-10 {
  width: 2.5rem
}

.w-10\/12 {
  width: 83.333333%
}

.w-11 {
  width: 2.75rem
}

.w-12 {
  width: 3rem
}

.w-14 {
  width: 3.5rem
}

.w-16 {
  width: 4rem
}

.w-18 {
  width: 4.5rem
}

.w-2 {
  width: .5rem
}

.w-2\.5 {
  width: .625rem
}

.w-2\/12 {
  width: 16.666667%
}

.w-20 {
  width: 5rem
}

.w-24 {
  width: 6rem
}

.w-28 {
  width: 7rem
}

.w-3 {
  width: .75rem
}

.w-3\.5 {
  width: .875rem
}

.w-3\/12 {
  width: 25%
}

.w-32 {
  width: 8rem
}

.w-36 {
  width: 9rem
}

.w-4 {
  width: 1rem
}

.w-4\.5 {
  width: 1.125rem
}

.w-4\/12 {
  width: 33.333333%
}

.w-40 {
  width: 10rem
}

.w-48 {
  width: 12rem
}

.w-5 {
  width: 1.25rem
}

.w-5\.5 {
  width: 1.375rem
}

.w-5\/12 {
  width: 41.666667%
}

.w-56 {
  width: 14rem
}

.w-6 {
  width: 1.5rem
}

.w-6\/12 {
  width: 50%
}

.call-svg-wh {
  width: 1em !important;
  height: 1em;
}

.call-svg-font {
  font-size: 17px !important;
}

.call-svg-font15 {
  font-size: 16px !important;
}

.w-60 {
  width: 15rem
}

.w-64 {
  width: 16rem
}

.w-7 {
  width: 1.75rem
}

.w-7\/12 {
  width: 58.333333%
}

.w-72 {
  width: 18rem
}

.w-8 {
  width: 2rem
}

.w-8\/12 {
  width: 66.666667%
}

.whitemode {
  cursor: not-allowed !important;
}

:where(.css-dev-only-do-not-override-1sn0ohr).ant-drawer .ant-drawer-body {
  padding: 10px 24px 0 !important;
}


.w-80 {
  width: 20rem
}

.w-9 {
  width: 2.25rem
}

.w-9\/12 {
  width: 75%
}

.w-\[calc\(100vw-2rem\)\] {
  width: calc(100vw - 2rem)
}

.w-full {
  width: 100%
}

.w-max {
  width: max-content
}

.contact-emoji {
  align-items: center;
  padding-left: 15px;
}

.call-emoji {
  align-items: center;
  padding-left: 34px;
}

.call-num {
  width: 15px;
  height: 15px;
  border-radius: 50%;
  border: 1px solid #fd4040d4;
  color: #fff;
  font-size: 11px;
  background-color: #fd4040d4;
  margin-left: 50px;
  margin-top: 6px;
  span {
    padding-top: 2px;
    padding-left: 0.5px;
    display: block;
  }
}

.call-num-pad {
  td {
    padding: 15px 15px 9px;
  }
}

.w-px {
  width: 1px
}

.min-w-\[1\.125rem\] {
  min-width: 1.125rem
}

.min-w-\[1\.25rem\] {
  min-width: 1.25rem
}

.min-w-\[1\.75rem\] {
  min-width: 1.75rem
}

.min-w-\[1rem\] {
  min-width: 1rem
}

.min-w-\[2\.5rem\] {
  min-width: 2.5rem
}

.min-w-\[20rem\] {
  min-width: 20rem
}

.min-w-\[2rem\] {
  min-width: 2rem
}

.min-w-\[7rem\] {
  min-width: 7rem
}

.min-w-full {
  min-width: 100%
}

.max-w-2xl {
  max-width: 42rem
}

.max-w-4xl {
  max-width: 56rem
}

.max-w-\[16rem\] {
  max-width: 16rem
}

.max-w-\[26rem\] {
  max-width: 26rem
}

.max-w-lg {
  max-width: 32rem
}

.max-w-md {
  max-width: 28rem
}

.max-w-screen-lg {
  max-width: 1024px
}

.max-w-sm {
  max-width: 24rem
}

.max-w-xl {
  max-width: 36rem
}

.max-w-xs {
  max-width: 20rem
}


:is(.dark .dark\:bg-accent) {
  background-color: #64748b;
}

:is(.dark .dark\:text-navy-100) {
  color: #c2c9d6;
}

:is(.dark .dark\:border-navy-700) {
  border-color: #26334d;
}

.bg-slate-300 {
  background-color: #cbd5e1;
}

.text-white {
  color: #fff;
}

.bg-primary {
  background-color: #64748b;
}

.bg-info {
  background-color: #64748b
}

.rounded-full {
  border-radius: 9999px;
}

.justify-center {
  justify-content: center;
}

.justify-between {
  justify-content: space-between;
}

.items-center {
  align-items: center;
}

.flex {
  display: flex;
}

.mt-1 {
  margin-top: 0.25rem;
}

.mt-3 {
  margin-top: 0.75rem;
}

.mt-4 {
  margin-top: 1rem
}

.mr-3 {
  margin-right: 0.75rem;
}

.mr-1 { 
  margin-right: 12px;
}

.mr-1\.5 {
  margin-right: 0.375rem;
}

.mt-10 {
  margin-top: 10px;
}

a {
  color: #242424;

  &:hover {
    color: #6571ff;
  }
}

.fs-14 {
  font-size: 14px;
}

.fs-12 {
  font-size: 12px;
}

.fs-17 {
  font-size: 17px;
}

.name-circle {
  font-size: 16px;
}

.cursor-pointer {
  cursor: pointer;
}

.cursor-default {
  cursor: default;
}

// 버튼
.space-y-3\.5>:not([hidden])~:not([hidden]) {
  --tw-space-y-reverse: 0;
  margin-bottom: calc(0.875rem*var(--tw-space-y-reverse));
  margin-top: calc(0.875rem*(1 - var(--tw-space-y-reverse)));
}

.btn-success {
  color: #64748b;
  background-color: #eee;
  border: 1px solid #eee;
  padding: 0.4rem 1.25rem;
  width: 93%;
  margin: 18px auto 0;
}

.btn {
  outline: 2px solid transparent;
  outline-offset: 2px;
}

.btn {
  align-items: center;
  border-radius: 0.5rem;
  cursor: pointer;
  display: inline-flex;
  justify-content: center;
  letter-spacing: .025em;
  padding: 0.5rem 1.25rem;
  text-align: center;
  transition-duration: .2s;
  transition-property: all;
  transition-timing-function: cubic-bezier(.4, 0, .2, 1);
}

[type=button],
[type=reset],
[type=submit],
button {
  -webkit-appearance: button;
  background-color: transparent;
  background-image: none;
}

.btn:hover {
  color: #6571ff;
  border: 1px solid #6571ff;
}

// 왼쪽 메뉴 모달 스크롤바
.overflow-y-auto {
  overflow-y: auto;
}

.flex-col {
  flex-direction: column;
}

.flex-grow,
.grow {
  flex-grow: 1;
}

.flex {
  display: flex;
}

.call-scroll::-webkit-scrollbar {
  display: none;
}

//파일 헤더
.header-nav {
  background-color: #fff;
  border-bottom: 1px solid #e9eef5;
  width: 100%;
  height: 62px;
  position: absolute;
  top: 0;
  right: 0;
  display: flex;
  justify-content: space-between;
  align-items: center;

  button {
    color: #3f4a5b;
    border: none;

    &:hover {
      border: none;
    }
  }
}

//알람헤더
.alarm-tree-content {
  margin-left: 369px;
  padding-top: 10px;
  // width: calc(100% - 369px);
  position: relative;
}

.alarm-tree-hidden {
  margin-left: 70px ;
  // width: calc(100% - 70px);
}

.alarm-header-nav {
  background-color: #fff;
  border-bottom: 1px solid #e9eef5;
  width: 100%;
  height: 62px;
  position: absolute;
  top: 0;
  right: 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-right: 12px;

  button {
    color: #3f4a5b;
    border: none;

    &:hover {
      border: none;
    }
  }
}

.alarm-tree-content {
  .alarm-header-nav {
    // padding-right: 25px;

    .alarm-header-name {
      h3 {
        margin: 0 0 0 30px;
      }
    }
  }
}

.alarm-table {
  border: 1px solid #e9eef5;
  margin-top: 10px;
  width: 100%;
  text-align: left;

  thead {
    th {
      padding: 10px 10px 10px 15px ;
      background: #e9eef5;

      &:first-child {
        width: 20% !important;
      }

      &:nth-child(2) {
        width: 25% !important;
      }

      &:nth-child(3) {
        width: 20% !important;
      }

      &:nth-child(4) {
        width: 20% !important;
      }

      &:nth-child(5) {
        width: 10% !important;
      }
    }



  }

  tbody {
    td {
      padding: 10px 10px 10px 15px !important;
      text-align: left;

      &:first-child {
        div {
          align-items: center;
          display: flex;

          svg {
            color: #eab308;
          }

          span {
            margin-left: 0.5rem;
          }
        }
      }
    }
  }
}


//채팅헤더(빈페이지)
.chat-header-nav {
  background-color: #fff;
  border-bottom: 1px solid #e9eef5;
  width: calc(100% - 70px);
  height: 62px;
  margin-left: 70px;
  position: absolute;
  top: 0;
  right: 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-right: 10px;

  button {
    color: #3f4a5b;
    border: none;

    &:hover {
      border: none;
    }
  }
}


//연락처 유저 정보 모달
.userinfo-modal {
  .ant-modal-content {
    height: 430px !important;
  }
}

//통화 헤더
.call-header-nav {
  background-color: #fff;
  border-bottom: 1px solid #e9eef5;
  width: 100%;
  height: 62px;
  position: absolute;
  top: 0;
  right: 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 12px 0 0;

  button {
    color: #3f4a5b;
    border: none;

    &:hover {
      border: none;
    }
  }
}

.call-info-btn button {
  color: #3f4a5b;
  border: none;
}

.call-info-btn button:hover {
  border: none;
}

.call-header-h3 {
  font-size: 16px;
  align-self: center;
  padding-left: 30px;
}

.callinfo-name {
  position: relative;
  width: 80px !important;
  height: 80px !important;
  border-radius: 40px !important;
  font-size: 30px !important;
}

.call-info-tab button {
  color: #3f4a5b;
  border: none;
  border-radius: 0px;
}

.call-info-tab button:hover {
  border: none;
}

.callinfo-sidebar {
  height: 100vh;
  background: #fff;
  display: flex;
  flex-direction: column;
  --tw-translate-x: -100%;
  flex-shrink: 0;
  right: 0;
  position: fixed;
  top: 0;
  transform: translate3d(var(--tw-translate-x), var(--tw-translate-y), 0) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  transition-duration: 0.2s;
  transition-property: transform;
  width: 330px;
  z-index: 60;
  border-left: 1px solid #e9eef5;
}

.call_title {
  display: flex;
  align-items: center;
  padding: 10px 20px;
  border-bottom: 1px solid #e9eef5;
  justify-content: space-between;
  position: sticky;
  top: 0;
  left: 0;
  background: #fff;
  z-index: 99999;
}

.call-info-histroy {
  border: 1px solid #e9eef5;
  text-align: left;
  padding: 10px 16px;
  width: 80%;
  margin: 0 auto;
}

.call-info-histroy {
  h4 {
    margin-top: 0;
  }
}

.call-name {
  a {
    &:hover {
      color: #000;
    }
  }
}

.call-table {
  border-bottom: 1px solid #e9eef5;
  margin-top: 10px;
  width: 100%;
  text-align: left;

  tbody {
    td {
      padding: 10px 10px 10px 15px !important;
      text-align: left;
    }
  }
}

@media screen and (max-width: 600px) {
.file-tree-hidden {
  .call-header-nav {
    padding-right: 16px;

    .call-header-name {
      h3 {
        margin: 0 0 0 27px;
      }
    }
  }
}
}
//검색창

.relative {
  position: relative;
}

:is(.dark .dark\:ring-accent\/50) {
  --tw-ring-color: rgba(95, 90, 246, .5);
}

:is(.dark .dark\:bg-navy-900\/90) {
  background-color: rgba(25, 33, 50, .9);
}

.text-xs\+ {
  font-size: .8125rem;
  line-height: 1.125rem;
}

.bg-slate-150 {
  background-color: #e9eef5;
}

.bg-slate-200 {
  background-color: #e2e8f0;
}

.rounded-lg {
  border-radius: 0.5rem;
}

.form-input {
  contain: paint;
}

.form-input {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  letter-spacing: .025em;
  outline: 2px solid transparent;
  outline-offset: 2px;
}

button,
input,
optgroup,
select,
textarea {
  color: inherit;
  font-family: inherit;
  font-size: 100%;
  font-weight: inherit;
  line-height: inherit;
  margin: 0;
  padding: 0;
}

:is(.dark .dark\:text-navy-300) {
  color: #697a9b;
}

.text-slate-400 {
  color: #94a3b8;
}

.pointer-events-none {
  pointer-events: none;
}

input {
  border: none;
}

//조직도 svg
.text-warning {
  color: #ff9800;
}

.font-medium {
  font-weight: 500;
}

.text-xs\+ {
  font-size: .8125rem;
  line-height: 1.125rem;
}

.treemenu {
  ul {
    li {
      div {
        cursor: pointer;
      }
    }
  }
}

.hover\:bg-slate-100:hover {
  background-color: #f1f5f9
}

//채팅 image
.avatar2 {
  display: inline-flex;
  flex-shrink: 0;
  height: 2rem;
  position: relative;
  width: 2rem;
  margin-right: 15px;

  img {
    -o-object-fit: cover;
    object-fit: cover;
    -o-object-position: center;
    object-position: center;
    height: 100%;
    width: 100%;
  }

  .is-initial {
    height: 100%;
    width: 100%;
  }
}

.border-white {
  border-color: #fff;
}

.border-2 {
  border-width: 2px;
}

.rounded-full {
  border-radius: 9999px;
}

img,
video {
  height: auto;
  max-width: 100%;
}

.flex-1 {
  flex: 1 1 0%;
}

.right-0 {
  right: 0;
}

//채팅 메뉴
.hover\:bg-slate-150:hover {
  background-color: #e9eef5
}

.menu {
  cursor: pointer;
}

//채팅 생성 모달
.chat-create-modal {
  .ant-modal-content {
    width: 450px !important;
    height: 680px !important;
  }
}

.chat-useat {
  width: 380px;
}

.chatsendbtn {
  color: #fff !important;
  border-color: #1890ff !important;
  background: #1890ff !important;
  text-shadow: none !important;
  box-shadow: none !important;
}

.chat-create-footer {
  display: flex;
  justify-content: flex-end;
  margin-top: 32px;
}

//파일
.avatar-file {
  display: inline-flex;
  flex-shrink: 0;
  height: 1.5rem;
  position: relative;
  width: 2rem;
  align-items: center;

  svg {
    width: 1.1rem;
    height: 1.1rem;
  }
}

.ant-tree .ant-tree-treenode {
  padding: 0px 18px 0px 14px !important;
  height: 34px !important;
}

.ant-tree .ant-tree-node-content-wrapper:hover {
  background-color: inherit !important;
  // color: #6571ff;
}

.ant-tree-node-content-wrapper .ant-tree-node-content-wrapper {}

.ant-tree.ant-tree-directory .ant-tree-treenode:hover:before {
  background: inherit !important;
}

// 눌렀을때 색
.ant-tree.ant-tree-directory .ant-tree-treenode-selected:before,
.ant-tree.ant-tree-directory .ant-tree-treenode-selected:hover:before {
  background: #fff;
}

.ant-tree-node-selected {
  color: rgba(0, 0, 0, .85) !important;
}

.ant-tree .ant-tree-node-content-wrapper .ant-tree-iconEle {
  margin-right: 8px;
}

.ant-tree.ant-tree-block-node .ant-tree-list-holder-inner .ant-tree-node-content-wrapper {
  display: flex;
}

.ant-tree-title {
  width: 100%;
}

.tree-fill-wrap {
  display: flex;
  align-items: center;
  justify-content: space-between;
}



table {
  thead {
    tr {
      th {
        border-bottom: 2px solid #e9eef5;
        padding: 10px;
      }
    }
  }

  tbody {
    tr {
      td {
        padding: 11px 0;
        border-bottom: 1px solid #e9eef5;

        &:first-child {
          line-height: 10px;
          text-align: left;
          width: 70%;
        }

        // &:nth-child(2) {
        //   text-align: left;
        //   width: 45%;
        // }
        &:last-child {
          text-align: center;
          width: 30%;
        }
      }
    }
  }
}

//연락처 유저모달
.mail-icon {
  margin-right: 10px;

  svg {
    width: 17px;
    height: 17px;
  }
}

//사용자 추가 테이블 스크롤
.chat-add-table {
  min-width: 0px !important;

  thead {
    tr {
      th {
        background: #e9eef5;

        &:first-child {
          width: 5% !important;
        }

        &:last-child {
          width: 7%;
        }
      }
    }
  }

  tbody {
    tr {
      td {
        &:first-child {
          width: 5%;
          text-align: center;
        }

        &:last-child {
          width: 7%;
          text-align: center;
        }
      }

    }
  }
}

.page-link {
  padding: 10px;
  color: #3f4a5b;
  ;

  // &:hover {
  //   background: #ebebeb82;
  // }
}

.pagenation-color {
  color: #6571ff;
}

//더보기버튼
.plus-btn {
  font-size: 13px;
  margin: 10px 14px 0 0;
  text-align: right;

  div {
    display: inline;
    cursor: pointer;

    span {
      padding-right: 5px;
    }
  }

}

.left_logo {
  position: fixed;
  padding: 15px 19px;
  background-color: #fff;
}

:where(.css-dev-only-do-not-override-1sn0ohr).ant-dropdown .ant-dropdown-menu, :where(.css-dev-only-do-not-override-1sn0ohr).ant-dropdown-menu-submenu .ant-dropdown-menu {
  padding: 7px !important;;
}

.group-left {
  left: 71px !important;
}

//call modal
.btn-danger-call {
  color: #fff;
  background-color: #ef476f !important;
  border: none !important;

  &:hover {
    color: #fff !important;
    background-color: #d1375b !important;
  }
}

.btn-success-call {
  color: #fff;
  background-color: #06d6a0 !important;
  border: none !important;

  &:hover {
    color: #fff !important;
    background-color: #07b889 !important;
  }
}

.rounded-circle {
  border-radius: 50% !important;
}

.avatar-sm {
  height: 3rem;
  width: 3rem;
}

.hr-style {
  width: 100%;
  border: 1px solid #f0f0f0;
  margin-top: 1rem;
}

.call-table-wrap {
  padding: 0 28px 20px;
  margin-top: 80px;
}

.call-modal .ant-modal-body {
  padding: 35px;
}

.call-modal .ant-modal-content {
  width: 410px;
  height: 340px;
  margin: 0 auto;
}

.call-modal .ant-modal-footer {
  display: none;
}

//call modal
.btn-danger-call {
  color: #fff;
  background-color: #ef476f !important;
  border: none !important;

  &:hover {
    color: #fff !important;
    background-color: #d1375b !important;
  }
}

.btn-success-call {
  color: #fff;
  background-color: #06d6a0 !important;
  border: none !important;

  &:hover {
    color: #fff !important;
    background-color: #07b889 !important;
  }
}

.rounded-circle {
  border-radius: 50% !important;
}

.avatar-sm {
  height: 3rem;
  width: 3rem;
}

.hr-style {
  width: 100%;
  border: 1px solid #f0f0f0;
  margin-top: 1rem;
}

.call-modal .ant-modal-body {
  padding: 35px;
}

.call-modal .ant-modal-content {
  width: 360px;
  height: 380px;
  margin: 0 auto;
}

.call-modal .ant-modal-footer {
  display: none;
}

.call-modal {
  top: 20% !important;
}

.ant-modal-body {
  padding: 35px;
}

.ant-modal-content {
  width: 426px;
  height: 549px;
  margin: 0 auto;
}

.ant-modal-footer {
  display: none;
}

.ant-tree .ant-tree-node-content-wrapper.ant-tree-node-selected {
  background-color: inherit !important;
  color: #6571ff;
}

.grid {
  display: grid;
}

.grid-cols-4 {
  grid-template-columns: repeat(4, minmax(0, 1fr));
}

.gap-2 {
  gap: 0.5rem;
}

.object-center {
  object-position: center;
}

.object-cover {
  object-fit: cover;
}

.rounded-lg {
  border-radius: 0.5rem;
}

.aspect-square {
  aspect-ratio: 1/1;
}

.alarm-table-wrap {
  margin-top: 80px;
  padding: 0 28px 20px;
}

.logout_btn {
  font-size: 22px;
  padding: 4px 0 3px;
}

.setline {
  line-height: 0.8;
}


::-webkit-scrollbar-track {
  border-radius: 2px;
}

::-webkit-scrollbar {
  width: 4px;
  height: 4px;
  background-color: #F7F7F7;
}

::-webkit-scrollbar-thumb {
  border-radius: 10px;
  background-color: #BFBFBF;
}

@media screen and (max-width: 800px) {
  .alarm-tree-content {
    .alarm-table-wrap {
      overflow-x: scroll;
    }

    .table-btn {
      min-width: 550px;
    }

    .alarm-table {
      min-width: 550px;
    }
  }
}

@media screen and (max-width: 550px) {
  .call-header-h3 {
    padding-left: 8px;
  }
}

@media screen and (max-width: 610px) {
  .alarm-tree-content {
    .alarm-header-btn {
      display: none;
    }
  }

  .alarm-tree-hidden {
    .alarm-header-btn {
      display: block;
    }
  }

  //채팅 생성 모달
  .chat-create-modal {
    .ant-modal-content {
      width: 330px !important;
      height: 700px !important;
    }
  }

  .chat-useat {
    width: 260px;
  }

  .call-search-wd {
    width: 500px;
  }
}

@media screen and (max-width: 500px) {
  .call-table-wrap {
    padding: 0 0 30px 18px
  }

  .call-modal {
    top: 10% !important;
    left: 5px;
  }

  // 연락처 통화모달
  .call-modal .ant-modal-content {
    width: 340px;
    height: 380px;
    margin: 0 auto;
  }

  // 활동 모바일
  .alarm-table-wrap {
    padding: 0 0 20px 10px;
  }

  .alarm-tree-content {
    .alarm-header-nav {
      padding-right: 16px;

      .alarm-header-name {
        h3 {
          margin: 0 0 0 30px;
        }
      }
    }
  }

  .alarm-table-wrap {
    overflow-x: scroll;
  }

  .alarm-table {
    width: 600px;
  }

  .table-btn {
    width: 600px;
  }

 
}

@media screen and (max-width: 380px) {

  // 연락처 통화모달
  .call-modal .ant-modal-content {
    width: 300px;
    height: 400px;
    margin: 0 auto;
  }
}

@media screen and (max-width: 326px) {

  // 연락처 통화모달
  .call-modal .ant-modal-content {
    width: 250px;
    height: 380px;
    margin: 0 auto;
  }
}

// fold 반응형
@media screen and (max-width: 371px) {
  .left_modal {
    width: 220px;
  }

  .ant-modal-content {
    width: 267px !important;
  }

  .fold-12 {
    font-size: 12px !important;
  }

  .fold-pl {
    padding-left: 5px !important;
    padding-bottom: 12px;
  }

  .flex-none {
    display: block !important;
  }

  .call-svg-font {
    font-size: 16px !important;
  }

  .call-svg-font15 {
    font-size: 15px !important;
  }

  .fold-none {
    display: none;
  }

  .fold-padding0 {
    padding-right: 0px !important;
  }

  .chatinfo-sidebar {
    width: 279px;
  }

  .fold_text_left {
    text-align: left !important;
  }

  .alarm-header-nav button {
    width: 18px !important;
    margin-right: 5px;
  }

  .fold-chat-width {
    width: 18px !important;
  }

  .group-left {
    left: 63px !important;
  }

  .w-9 {
    width: 1.7rem;
  }

  .w-8 {
    width: 2rem;
  }

  .contact-table-mb {
    font-size: 12px !important;
}

  .avatar2 {
    margin-right: 10px;
  }

  .chat-message-time {
    font-size: 13px;
    margin-bottom: 6px;
  }

  .call-margin10 {
    margin-right: 6px !important;
    margin-left: 15px;
  }

  .call-margin5 {
    margin-right: 5px !important;
  }

  :where(.css-dev-only-do-not-override-1sn0ohr).ant-dropdown .ant-dropdown-menu, :where(.css-dev-only-do-not-override-1sn0ohr).ant-dropdown-menu-submenu .ant-dropdown-menu {
    padding: 0px !important;
    padding-left: 4px !important;
}

:where(.css-dev-only-do-not-override-1sn0ohr).ant-modal .ant-modal-content {
  padding: 15px 2px !important;
}

:where(.css-dev-only-do-not-override-1sn0ohr).ant-modal {
  margin: 0 7px !important;
}
}


// 모바일 반응형
@media screen and (max-width: 600px) {
  .left_modal {
    width: calc(100% - 70px) !important;
  }

  .chatinfo-sidebar {
    width: calc(100% - 70px) ;
  }
  .callinfo-sidebar {
    width: calc(100% - 70px);
  }

  

  .ant-modal-content {
    width: 330px;
    height: 520px;
    margin: 0 auto;
  }

  .ant-modal-body {
    padding: 0px;
    padding-top: 10px;
  }

  .side-setting-icon div:nth-child(1) svg {
    width: 20px;
    height: 20px;
  }

  .side-setting-icon div:nth-child(2) {
    padding: 5px 0 5px;
  svg {
    width: 20px;
    height: 20px;
  }
}

  .logout_btn {
    font-size: 20px;
    margin: 0px 0 3px;
  }

  .setline {
    line-height: 0.3;
  }
}

@media screen and (max-width: 600px) {
  .call-header-h3 {
    padding-left: 10px;
  }
}

@media screen and (max-width: 414px) {
  .group-left {
    left: 61px !important;
  }

  .call-header-h3 {
    padding-left: 0px;
  }

  .left_modal {
    width: calc(100% - 61px) !important;
  }

  .chatinfo-sidebar  {
    width: calc(100% - 60px);
  }

  .callinfo-sidebar {
    width: calc(100% - 60px);
  }

  .video_style2 {
    width: calc(100% - 60px);
  }

  .file-modal {
    left: 71px;
    height: 189px;
    width: 172px;
  }

  .ant-form-item {
    margin: 0 0 6px !important;
  }

  ::-webkit-scrollbar {
    background-color: inherit !important;
  }

  ::-webkit-scrollbar-thumb {
    background-color: inherit !important;
  }

  .header-nav {
    padding-right: 0px;
  }

  .chat-message-item .chat-message-content .chat-message-info.myself {
    display: block !important;
  }

  .chat-message-item .chat-message-content .chat-message-info {
    display: block !important;
    margin-bottom: 5px;
  }

  .chat-message-item {
    margin-bottom: 0px;
  }

  .chat-message-item .chat-message-content .chat-message-info.myself .chat-message-receiver {
    text-align: left !important;
  }

  .video_style1 {
    width: 59px;
  }

  .left_logo {
    padding: 16px 13px 16px 15px !important;
  }

  .side-setting-icon {
    width: 58px;
  }

  .chat-wrap {
    margin-left: 59px;
  }

  .viewport {
    margin-left: 58px;

  }
}
.call-createModal {
  .ant-modal-content {
    width: 450px;
    height: 410px;
  }
}

@media screen and (max-width: 500px) {
  .call-createModal {
    .ant-modal-content {
      width: 80%;
    }
  }
}

// 모바일
.ant-list-items {
  margin: 0;
  padding: 0;
  list-style: none;
}

.ant-list-item {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 12px 0;
  color: rgba(0, 0, 0, .88);
}

.ant-list-item-meta {
  display: flex;
  flex: 1;
  align-items: flex-start;
  max-width: 100%;
}

.ant-list-item-meta-avatar {
  margin-inline-end: 16px;
}

.ant-list-item-meta-content {
  flex: 1 0;
  width: 0;
  color: rgba(0, 0, 0, .88);
  text-align: left;
}

.ant-list-item-meta-title {
  margin: 0 0 4px;
  color: rgba(0, 0, 0, .88);
  font-size: 14px;
  line-height: 1.5714285714285714;
}

.ant-list-item-meta-description {
  color: rgba(0, 0, 0, .45);
  font-size: 12px;
  line-height: 1.5714285714285714;
}

.ant-list-item-action {
  flex: 0 0 auto;
  margin-inline-start: 48px;
  padding: 0;
  font-size: 0;
  list-style: none;
}

// 모바일테이블
.call-table-mb {
  width: 100%;
  tbody{
    tr{
      td{
        &:first-child{
          width: 3%;
        }
        // &:nth-child(2){
        //   padding-left: 10px;
        // }
        // &:nth-child(3){
        //   width: 15%;
        // }
        // &:last-child{
        //   width: 5%;
        // }
      }
    }
  }
  .call-name{
    display: flex;
    flex-wrap: wrap;
    align-items: center;
  }
}

@media screen and (max-width: 370px) {
  .call-time-mb{
    text-align: left;
    // margin-top: 5px;
  }
}

.call-history-table{
  tbody{
    tr{
      td{
        &:last-child{
          width: 10%;
        }
      }
    }
  }
}
.name-circle-mb{
  margin-right: 5px;
}
// s8 반응형
@media screen and (max-width: 360px) {
  .left_modal {
    width: 289px;
  }

  .chatinfo-sidebar {
    width: 289px;
  }
  .fold-pl{
    padding-left: 18px !important;
  }
}
.pagination-web{
  margin-top: 35px;
  cursor: pointer;
  text-align: center;
  display: flex;
  justify-content: center;
  div{
    width: 28px;
    height: 28px;
    line-height: 28px;
    &:hover{
      border-radius: 5px;
      color: #6571ff;
      line-height: 28px;
    }
  }
}
.web-tr-hover{
  &:hover{
    cursor: pointer;
  }
}