//조직도 헤더
.group-header-nav {
    background-color: #fff;
    border-bottom: 1px solid #e9eef5;
    width: 100%;
    height: 62px;
    position: absolute;
    top: 0;
    right: 0;
    display: flex;
    justify-content: space-between;
    align-items: center;

    button {
        color: #3f4a5b;
        border: none;

        &:hover {
            border: none;
        }
    }
}

.group-tree-content {
    .group-header-nav {
        padding-right: 12px;

        .group-header-name {
            h3 {
                margin: 0 0 0 28px;
            }
        }
    }
}

.group-tree-hidden {
    .group-header-nav {
        padding-right: 20px;

        .group-header-name {
            h3 {
                margin: 0 0 0 42px;
            }
        }
    }
}

//조직도 버튼
.group-btn {
    border: none;

    &:hover {
        border: none;
    }
}

.group-table-btn {
    display: flex;
    justify-content: flex-end;
    margin-top: 34px;
    width: 98%;
    flex-wrap: wrap;
}

.group-left {
    left: 71px ;
}

//그룹 테이블 모바일
.group-table-mb {
    border: 1px solid #e9eef5;
    width: 100%;
    margin-top: 10px;

    thead {
        th {
            padding: 10px !important;
            background: #e9eef5;

            &:nth-child(1) {
                width: 33% !important;
            }

            &:nth-child(2) {
                width: 33%;
            }

            &:nth-child(3) {
                width: 33%;
            }
        }
    }

    tbody {
        td {
            padding: 10px !important;

            &:nth-child(1) {
                width: 5%;
                text-align: center;
            }

            &:nth-child(2) {
                line-height: 22px;
                text-align: left;
                width: 33%;
            }

            &:nth-child(3) {
                text-align: center;
                width: 33%;
            }

            &:nth-child(4) {
                text-align: center;
            }
        }
    }
}

.group-table-btn-mb {
    width: 100%;

    button {
        &:nth-child(2) {
            margin-right: 5px;
        }
    }
}

//그룹
.group-tree-content {
    margin-left: 369px;
    padding-top: 10px;
    position: relative;
    // padding: 132px 0 28px 20px;
}

.group-table-wrap {
    padding: 0 28px 20px;
    margin-top: 80px;
    overflow-x: inherit;
    width: 96%;
}

.group-tree-hidden {
    margin-left: 69px;
    // padding: 62px 0 0 30px;
}

.group-table {
    border: 1px solid #e9eef5;
    width: 100%;
    margin-top: 10px;

    thead {
        th {
            padding: 10px !important;
            background: #e9eef5;

            &:nth-child(1) {
                width: 33%;
            }

            &:nth-child(2) {
                width: 33%;
            }

            &:nth-child(3) {
                width: 33%;
            }
        }
    }

    tbody {
        td {
            padding: 10px !important;

            &:nth-child(1) {
                width: 5%;
                text-align: center;
            }

            &:nth-child(2) {
                line-height: 22px;
                text-align: center;
                width: 33%;
            }

            &:nth-child(3) {
                text-align: center;
                width: 33%;
            }

            &:nth-child(4) {
                text-align: center;
            }
        }
    }
}

//그룹 생성 모달
.group-create-modal {
    .ant-modal-content {
        width: 450px !important;
        height: 410px !important;
    }
}


.group-info-sidebar {
    height: 100vh;
    background: #fff;
    display: flex;
    flex-direction: column;
    --tw-translate-x: -100%;
    flex-shrink: 0;
    right: 0;
    position: fixed;
    top: 0;
    transform: translate3d(var(--tw-translate-x), var(--tw-translate-y), 0) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
    transition-duration: 0.2s;
    transition-property: transform;
    width: 330px;
    z-index: 60;
    border-left: 1px solid #e9eef5;
}


@media screen and (max-width: 1200px) {

    .group-tree-content {
        // padding: 62px 0 20px 0px;

        .group-table {
            // margin-right: 38px;
        }
    }
}

@media screen and (max-width: 630px) {

    //조직도 왼쪽모달 있을때
    .group-tree-content {
        .group-header-btn {
            display: none;
        }
    }

    .group-tree-hidden {
        .group-header-btn {
            display: block;
        }
    }

    .group-tree-content {
        .group-table-wrap {
            overflow-x: scroll;

            .group-table {
                width: 500px;
            }

            .group-table-btn {
                width: 500px;
            }
        }
    }

}

@media screen and (max-width: 600px) {
    .group-create-modal {
        .ant-modal-content {
            width: 80% !important;
            height: 384px !important;

            .ant-modal-body {
                padding: 10px !important;
            }
        }
    }
}

@media screen and (max-width: 500px) {
    .group-tree-content {
        .group-header-nav {
            padding-right: 16px;

            .group-header-name {
                h3 {
                    margin: 0 0 0 46px;
                    width: 53px;
                }
            }
        }
    }

    // 조직도
    .group-tree-hidden .group-header-nav {
        padding-right: 16px;
    }

    .group-tree-hidden {
        margin-left: 50px;
        // margin-right: 40px;
    }

    .group-table-wrap {
        overflow-x: scroll;
        margin-right: 18px;
        width: 90%;
    }

    .group-table {
        width: 500px;
    }

    .group-table-btn {
        width: 500px;
    }

    .group-tree-content .group-table {
        margin-right: 0;
    }

    .mb-info-text {
        font-size: 12px;
    }
}

.group-create-footer {
    display: flex;
    justify-content: flex-end;
    margin-top: 15px;
}

@media screen and (max-width: 375px) {
    .group-tree-hidden {
        margin-left: 46px;
    }

    .group-table-btn-mb {
        button {
            &:nth-child(2) {
                margin-right: 5px;
            }

            &:last-child {
                margin-top: 10px;
            }
        }
    }
}

@media screen and (max-width: 360px) {
    .group-table-btn-mb {
        button {
            &:nth-child(2) {
                margin-right: 0px;
            }

            &:last-child {
                margin-top: 10px;
            }
        }
    }
}

// fold 반응형
@media screen and (max-width: 285px) {
    .group-info-sidebar {
        width: 100%;
    }
}


@media screen and (max-width: 414px) {
    .group-info-sidebar {
        width: calc(100% - 60px) !important;
    }
}

@media screen and (max-width: 600px) {
    .group-info-sidebar {
        width: calc(100% - 70px);
    }
}