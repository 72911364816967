.chat-container {
  width: 100vw;
  height: 100vh;
  background: rgba(0, 0, 0, 0.6);
  display: flex;
  align-items: center;
  justify-content: center;

  .chat-wrap {
    width: 60vw;
    max-width: 800px;
    height: 80vh;
    background: #fff;
    display: flex;
    flex-direction: column;
    border-radius: 16px;
    box-shadow: 0 10px 20px rgba(0, 0, 0, 0.5);
    box-sizing: border-box;
    padding: 20px 0;

    h2 {
      height: 45px;
      line-height: 45px;
    }

    .chat-message-wrap {
      flex-grow: 1;
      overflow-y: auto;
      border-bottom: 1px solid #eee;
    }

    .chat-message-box {
      height: 55px !important;
      box-sizing: border-box;
      padding: 0 5px;

      >textarea {
        padding: 5px 20px;
        height: 100%;
        width: 100%;
        resize: none;
      }
    }
  }

  .chat-disabled {
    height: 155px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 18px;
    flex-shrink: 0;
  }
}

.sendBtn {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 19px;
  padding: 12px 10px 12px 2px;
  color: rgb(101, 113, 255);
  cursor: pointer;
  border-left: none;

  &:hover {
    // border: 1px solid #62b1fa;
    color: #6571ff;

    svg {
      // fill: #1890ff;
    }
  }
}

.textareaStyle {
  resize: none;

  &::-webkit-scrollbar {
    display: none;
  }
}

.chat-wrap {
  height: 100vh;
  background: #fff;
  display: flex;
  flex-direction: column;
  margin-left: 70px;

  h2 {
    height: 45px;
    line-height: 45px;
  }

  .chat-message-wrap {
    flex-grow: 1;
    overflow-y: auto;
    // margin-bottom: 130px;
    // border-bottom: 1px solid #eee;
  }

  .chat-message-box {
    height: 100px;
    box-sizing: border-box;
    padding: 8px 0px 10px 10px;
    border-top: 1px solid #e9eef5;

    >textarea {
      padding: 5px 20px;
      height: 100%;
      width: 100%;
      resize: none;
      border: none;

      &:hover {
        border: none;
      }

      &:focus {
        box-shadow: none;
      }
    }
  }
}

.chat-message-input {
  // margin-bottom: 10px;
}

.ant-dropdown-menu {
  border: 1px solid #e9eef5;
}

.chat-icon {
  display: flex;
  justify-content: space-between;
  text-align: center;
  align-items: center;
  font-size: 20px;
  position: relative;
  color: #3f4a5b;

  svg {
    margin: 5px 6px;
    cursor: pointer;

    &:hover {
      color: #6571ff;
    }
  }
}

/* 아래의 모든 코드는 영역::코드로 사용 */
.chat-message-wrap::-webkit-scrollbar {
  width: 5px;
  /* 스크롤바의 너비 */
}

.chat-message-wrap::-webkit-scrollbar-thumb {
  height: 30%;
  /* 스크롤바의 길이 */
  background: #adadad;
  /* 스크롤바의 색상 */

  border-radius: 10px;
}

.chat-message-wrap::-webkit-scrollbar-track {
  background: rgb(230, 230, 230);
  /*스크롤바 뒷 배경 색상*/
}

//아이콘
.chat-header {
  border-bottom: 1px solid #e9eef5;
  height: 55px;
  color: #3f4a5b;
}

.chat-head-icon {
  button {
    color: #3f4a5b;
    border: none;

    &:hover {
      border: none;
    }
  }
}

.popper-box {
  background-color: #fff !important;
  box-shadow: none !important;
  border: 1px solid #e9eef5;

  ul {
    li {
      a {
        color: #686a6e;

        &:hover {
          color: #6571ff;
        }
      }
    }
  }
}

//채팅 인포
.avatar-chat {
  display: inline-flex;
  flex-shrink: 0;
  height: 2rem;
  position: relative;
}

.chat-info-btn {
  button {
    color: #3f4a5b;
    border: none;

    &:hover {
      border: none;
    }
  }
}

.chatinfo-name {
  position: relative;
  width: 80px !important;
  height: 80px !important;
  border-radius: 40px !important;
  font-size: 30px !important;
}

.chat-info-tab {
  button {
    color: #3f4a5b;
    border: none;
    border-radius: 0px;

    &:hover {
      border: none;
    }

    // &:focus{
    //   border-bottom: 2px solid #6571ff !important;
    //   color: #6571ff;
    // }
  }
}

.chatinfo-sidebar {
  height: 100vh;
  background: #fff;
  display: flex;
  flex-direction: column;
  --tw-translate-x: -100%;
  flex-shrink: 0;
  right: 0;
  position: fixed;
  top: 0;
  transform: translate3d(var(--tw-translate-x), var(--tw-translate-y), 0) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  transition-duration: 0.2s;
  transition-property: transform;
  width: 330px;
  z-index: 60;
  color: #3f4a5b;
  border-left: 1px solid #e9eef5;
}

.chatinfo_title {
  display: flex;
  align-items: center;
  padding: 10px 20px;
  border-bottom: 1px solid #e9eef5;
  justify-content: space-between;
  position: sticky;
  top: 0;
  left: 0;
  background: #fff;
  z-index: 99999;
}

.mt-2 {
  margin-top: 0.5rem;
}

.info_hidden {
  flex: 2;
}

.emogi-modal {
  position: absolute;
  bottom: 98%;
  right: 8px;
  height: 260px;
  width: 317px;
  background-color: #fff;
  border: 1px solid #e9eef5;
  display: none;
}

.file-modal {
  position: absolute;
  bottom: 70px;
  left: 83px;
  height: 211px;
  width: 278px;
  background-color: #fff;
  border: 1px solid #e9eef5;
  z-index: 9;
  font-size: 14px;
  text-align: left;
  display: block;
  padding: 10px;

  li {
    padding: 6px 0 6px 30px;

    &:nth-child(1) {
      margin-top: 5px;
    }

    &:hover {
      background: #eee !important;
    }
  }
}

.file-modal-none {
  display: none;
}

.ant-form-item {
  margin: 0 0 10px !important;
}

.chatfile-modal {
  bottom: 52px !important;
  left: 8px !important;
}

.search-modal {
  width: 242px;
  position: absolute;
  top: 54px;
  right: 0;
  z-index: 9999;
}

.search-modal-video {
  width: 242px;
  position: absolute;
  top: 58px;
  right: 6px;
  z-index: 9999;
}



//생성버튼
.chat-createBtn {
  background-color: #e9eef5;
  border-radius: 50px;
  color: #7f8ca0;
  position: absolute;
  bottom: 14px !important;
  right: 14px;
  font-size: 18px;
  line-height: 35px;
  width: 35px;
  height: 35px;
  cursor: pointer;

  &:hover {
    background-color: #d3dbe6;
    color: #6571ff;
  }
}

//tab 메뉴
.ant-tabs>.ant-tabs-nav,
.ant-tabs>div>.ant-tabs-nav {
  padding: 10px 0px 0px 20px;
}

.tab-total {
  text-align: left;

  >li {
    border-bottom: 1px solid #f1f1f1;
    padding: 6px 0 6px 10px;
  }
}

.user-info {
  font-size: 16px;
  padding: 6px 0 6px 20px;
  border-bottom: 1px solid #f1f1f1;
  display: flex;
  justify-content: space-between;
  padding: 6px 20px;
  align-items: center;
  cursor: pointer;
}

.btn-video-info {
  border: none !important;
  width: 21px;
  margin-right: 7px;
}

.btn-call-info {
  border: none !important;
  width: 18px;
}



//call modal
.btn-danger-call {
  color: #fff;
  background-color: #ef476f !important;
  border: none !important;

  &:hover {
    color: #fff !important;
    background-color: #d1375b !important;
  }
}

.btn-success-call {
  color: #fff;
  background-color: #06d6a0 !important;
  border: none !important;
  position: relative;

  &:hover {
    color: #fff !important;
    background-color: #07b889 !important;
  }

  .call-spiner {
    position: absolute;
    z-index: 50;
    color: #1bb78d !important;

    &:hover {
      color: #2c9478 !important;
    }
  }
}

.rounded-circle {
  border-radius: 50% !important;
}

.avatar-sm {
  height: 3rem;
  width: 3rem;
}

.hr-style {
  width: 100%;
  border: 1px solid #f0f0f0;
  margin-top: 1rem;
}

.call-modal .ant-modal-body {
  padding: 35px;
}

.call-modal .ant-modal-content {
  width: 410px;
  height: 340px;
  margin: 0 auto;
}

.call-modal .ant-modal-footer {
  display: none;
}



//프리뷰 모달 preview modal
.preview-modal {
  .ant-modal-body {
    div {
      padding: 0;
    }
  }

  .js-preview-view {
    height: auto;

    .title-style {
      margin: 0;
      padding-bottom: 22px;
      text-align: center;
    }

    .preview-wrap {

      #js-preview-video {
        width: 497px;
        height: 282px;
        margin: 0;
      }

      .audio-test-wrap .speaker-output {
        margin-top: 8px;
      }

      .audio-test {
        margin-top: 24px;

        .audio-test-wrap {
          h3 {
            margin-bottom: 3px !important;
          }
        }
      }

      .video-footer {
        .microphone-footer {
          button {
            box-shadow: none;

            &:hover {
              background: none;
            }
          }
        }

        .camera-footer {
          button {
            box-shadow: none;

            &:hover {
              background: none;
            }
          }
        }

        .joinBtn {
          min-width: 40px;
          width: 83px !important;
          height: 42px !important;
          line-height: 50px;
          text-align: center;
          margin-right: min(30px, 1.5vw);
          cursor: pointer;

          svg {
            fill: #3f4a5b;
            width: 24px;
            height: 24px;

            &:hover {
              fill: #6571ff;
            }
          }
        }

        .outBtn {
          min-width: 40px;
          width: 83px !important;
          height: 42px !important;
          line-height: 50px;
          text-align: center;
          cursor: pointer;

          svg {
            fill: #3f4a5b;
            width: 24px;
            height: 24px;

            &:hover {
              fill: #6571ff;
            }
          }
        }

      }

      .speaker-action {
        button {
          line-height: 14px;

          span {
            font-size: 14px;
          }
        }

      }
    }
  }
}

@media screen and (max-width: 750px) {
  .head-user-name {
    display: none;
  }
}

@media screen and (max-width: 660px) {
  .preview-modal {
    .js-preview-view {
      padding-bottom: 0;
      height: auto;
    }

    .container {
      width: 100%;
    }

    .preview-video {
      width: 100%;
      height: auto;
    }

    .audio-test-wrap .speaker-action {
      flex-flow: column;
    }

    .audio-test {
      width: 100%;
    }

    .speaker-btn {
      margin-bottom: 13px;
    }
  }
}

.selectedmenu {
  color: #6571ff;
}

.filesendbtn {
  color: #fff !important;
  border-color: #1890ff !important;
  background: #1890ff !important;
  text-shadow: none !important;
  box-shadow: none !important;
  margin-top: 15px;
}

.filedown-info {
  font-size: 22px;
  color: #3f4a5bba;
  cursor: pointer;
}

//emogi 
.react-input-emoji--input {
  max-height: 35px !important;
}

.videoInputContainer {
  width: 81% !important;
  display: flex;
  align-items: center;
  position: relative;
  margin-right: 4px;
}

.chatInputContainer {
  width: 94%;
  display: flex;
  align-items: center;
  position: relative;
  flex: 2;

  >.react-emoji {
    width: calc(100% - 7px) !important;
  }
}

@media (max-width: 1534px) {
  .chatInputContainer {
    width: 93%;
  }
}

@media (max-width: 1318px) {
  .chatInputContainer {
    width: 92%;
  }
}

@media (max-width: 1180px) {
  .chatInputContainer {
    width: 90%;
  }
}

@media (max-width: 1097px) {
  .chatInputContainer {
    width: 88%;
  }
}

@media (max-width: 973px) {
  .chatInputContainer {
    width: 86%;
  }
}

@media (max-width: 873px) {
  .chatInputContainer {
    width: 84%;
  }
}

@media (max-width: 812px) {
  .chatInputContainer {
    width: 82%;
  }
}

@media (max-width: 748px) {
  .chatInputContainer {
    width: 80%;
  }
}

@media (max-width: 716px) {
  .chatInputContainer {
    width: 78%;
  }
}

@media (max-width: 680px) {
  .chatInputContainer {
    width: 76%;
  }
}

@media (max-width: 648px) {
  .chatInputContainer {
    width: 74%;
  }
}

@media (max-width: 655px) {
  .react-input-emoji--placeholder {
    font-size: 12px;
    line-height: 1.2;
  }
}

.react-input-emoji--input::-webkit-scrollbar {
  display: none;
}


// 채팅상단 버튼
.mr-2 {
  margin-right: 0.5rem;
}

.ml-1 {
  margin-left: 0.25rem;
}

.outline-none {
  outline: 2px solid transparent;
  outline-offset: 2px;
}

.ml-0\.5 {
  margin-left: 0.125rem;
}

.menu-togglespan {
  --tw-translate-x: 0px;
  background-color: currentColor;
  height: 2px;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  transition-duration: .15s;
  transition-duration: .25s;
  transition-property: all;
  transition-timing-function: cubic-bezier(.4, 0, .2, 1);
  width: 1.25rem;
  margin-top: 0.4rem;
}

.truncate {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  font-size: 13px;
  text-align: left;
}

.chat-create-closebtn {
  position: fixed;
  top: 19px;
  right: 16px;
  cursor: pointer;

  svg {
    width: 16px;
    height: 16px;
    color: rgba(0, 0, 0, 0.45);
  }

  &:hover {
    svg {
      color: #6571ff;
    }
  }
}

.chat-create-closebtn {
  position: fixed;
  top: 19px;
  right: 16px;
  cursor: pointer;

  svg {
    width: 16px;
    height: 16px;
    color: rgba(0, 0, 0, 0.45);
  }

  &:hover {
    svg {
      color: #6571ff;
    }
  }
}

.twotab-search-btn {
  width: 18px;
  cursor: pointer;

  &:hover {
    color: #6571ff;
  }
}

.tab-modal-group {
  width: 100%;
  height: 200px;
  overflow-y: scroll;
  margin-bottom: 20px;
}

.chat-group-modal {
  .anticon-close {
    svg {
      display: none;
    }

    &::after {
      content: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='16' height='16' fill='currentColor' class='bi bi-chevron-left' viewBox='0 0 16 16'%3E%3Cpath fill-rule='evenodd' d='M11.354 1.646a.5.5 0 0 1 0 .708L5.707 8l5.647 5.646a.5.5 0 0 1-.708.708l-6-6a.5.5 0 0 1 0-.708l6-6a.5.5 0 0 1 .708 0z'/%3E%3C/svg%3E");
      transform: rotate(180deg);
    }
  }
}

.chat-modal-list-item{
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 7px 0;
  color: rgba(0, 0, 0, 0.88);
  border-bottom: 1px solid rgba(0, 0, 0, 0.06);
}



// fold 반응형
@media (max-width: 285px) {
.search-modal {
  width: 206px;
  position: absolute;
  top: 54px;
  right: 14px;
}

.search-modal-video {
  width: 215px;
  position: absolute;
  top: 58px;
  right: -1px;
}
}