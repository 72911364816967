.alarm-name {
    position: relative;
    width: 33px !important;
    height: 33px !important;
    border-radius: 33px !important;
}

.alarm-emoji {
    position: absolute;
    bottom: -10px;
    right: -9px;
    width: 22px;
    background: #fff;
    border: 1px solid #ccc;
    border-radius: 23px;
    height: 23px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.alarm-heart {
    color: #f00;
    padding-top: 1px;
    
    svg {
        width: 0.8em;
        height: 0.8em;
    }
}

.alarm-handsup {
    color: #fdc049;

    svg {
        width: 0.9em;
        height: 0.9em;

    }
}

.alarm-at {
    color: #333;
    padding-left: 1px;
    
    svg {
        width: 1.1em;
        height: 1.1em;
    }
}

.alarm-hover:hover { background-color: #e9eef5;}

.alarm-first {
    padding: 10px 15px;
    margin-top: 10px;
}

.padding {
    padding: 10px 15px;
}

.fs-13 {font-size: 13px;}

.text-left {text-align: left;}

.text-right {text-align: right;}

.alarm-color {color: #615f5f;}

.margin-left15 {margin-left: 15px;}

.flex-2 {flex: 2;}

.pl-import { padding-left: 23px !important;}


.ant-list-item{
    border-bottom: 1px solid rgba(0,0,0,.06);
}

.ant-list-item-meta-description{
    font-size: 13px;
}

.alarm-create-modal{
    .ant-modal-content{
      width: 450px;
      height: 346px;
    }
  }
  @media screen and (max-width: 500px) {
    .alarm-create-modal{
      .ant-modal-content{
        width: 80%;
      }
    }
  }