.App {
  text-align: center;
  overflow-y: hidden;
}
p,li,ul,ol,h1,h2{
  padding: 0;
  margin: 0;
}
li{
  list-style: none;
}
.back-home {
  position: absolute;
  z-index: 99;
  right: 30px;
  bottom: 30px;
}
/* 이모지 css */
.react-emoji-picker--wrapper{
  right: -34px !important;
  bottom: -2px;
  border: 1px solid #eee;
  z-index: 9999999;
}

.react-input-emoji--button{
  display: flex;
  justify-content: space-between;
  text-align: center;
  align-items: center;
  font-size: 20px;
  color: #3f4a5b;
  padding-right: 4px;
}

.react-input-emoji--input {
  cursor: auto !important;
}

.react-input-emoji--button svg {
  width: 20px;
  height: 20px;
}

.react-input-emoji--button__show svg {
  fill: #3f4a5b;
}


@media screen and (max-width: 414px) {
.react-emoji-picker--wrapper{
  right: -36px !important;
  bottom: -2px;
}
}

* {
  word-break: keep-all;
}