@keyframes fadeIn {
  from {
    opacity: 0;
    /* 투명도를 0으로 설정하여 처음에 숨깁니다 */
  }

  to {
    opacity: 1;
    /* 투명도를 1로 설정하여 서서히 나타나게 합니다 */
  }
}

.nav {
  height: 8vh;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.navleave {
  margin-right: 30px;
  color: #fff;
  font-size: 17px !important;
}

.home-nav {
  display: flex;
  align-items: center;
  position: absolute;
  top: 20px;
  right: 0;

  >div>span {
    &:hover {
      color: #eee !important;
    }
  }

  >button {
    &:hover {
      color: #eee !important;
    }
  }
}


.emogi_nav {
  font-size: 22px;
  padding: 0 10px;
  cursor: pointer;
  color: #fff;

  &:hover {
    color: #eee;
  }
}

.pt-30 {
  padding-top: 3px;
}

// 로그인
.loginbtn {
  // background-color: #1890ff !important;
  background: linear-gradient(to right, #E91E63, #9C27B0) !important;
  cursor: pointer;
  width: 100%;
  font-size: 16px;
  margin-top: 5px;
  height: 46px;
}

.ant-checkbox-checked .ant-checkbox-inner {
  background: #E91E63 !important;
  border-radius: 50%;

  &:hover {
    border-radius: 50% !important;
    border: 1px solid #E91E63 !important;
  }
}

.ant-checkbox-inner {
  border-radius: 50% !important;

  &:hover {
    border-radius: 50% !important;
    border: none !important;
  }
}

.ant-checkbox {
  &:hover {
    border-radius: 50% !important;
    border: none !important;
  }
}

.ant-checkbox-wrapper {
  .ant-checkbox-inner {
    &:hover {
      border: none !important;
    }
  }
}

.ant-row {
  display: block !important;
}

.card-body {
  height: 100vh;
  display: flex;
  align-items: center;

  >div {
    >div {
      margin-bottom: 20px;
      text-align: -webkit-center;
    }
  }
}

.ant-col-16 {
  max-width: 100% !important;
}

.ant-col-8 {
  max-width: 100% !important;
  text-align: left;
}

.ant-col-offset-8 {
  margin-left: 0px !important;
}

.google {
  font-size: 18px;
  background: #ff3d50;
  color: #fff;
  border-radius: 50px;
  padding: 6px;
  cursor: pointer;
}

.facebook {
  font-size: 19px;
  background: #1890ff;
  color: #fff;
  border-radius: 50px;
  padding: 5px;
  cursor: pointer;
  margin-right: 6px;
}

.ant-form-item-label>label {
  font-size: 15px !important;
}

.ant-form label {
  font-size: 15px !important;
}

.card {
  --bs-card-spacer-y: 1rem;
  --bs-card-spacer-x: 1rem;
  --bs-card-title-spacer-y: 0.5rem;
  --bs-card-border-width: 0;
  --bs-card-border-color: #f6f6f6;
  ;
  --bs-card-border-radius: 0.375rem;
  --bs-card-inner-border-radius: calc(0.375rem - 1px);
  --bs-card-cap-padding-y: 0.5rem;
  --bs-card-cap-padding-x: 1rem;
  --bs-card-cap-bg: rgba(0, 0, 0, 0.03);
  --bs-card-bg: #fff;
  --bs-card-img-overlay-padding: 1rem;
  --bs-card-group-margin: 0.75rem;
  position: relative;
  display: flex;
  flex-direction: column;
  min-width: 0;
  height: 100vh;
  word-wrap: break-word;
  background-color: #fff;
  background-clip: border-box;
  border: var(--bs-card-border-width) solid var(--bs-card-border-color);
  border-radius: var(--bs-card-border-radius);
}

.logo {
  max-height: 100px;
  max-width: 100%;
  padding: 15px 20px;
  text-align: center;
  background-color: #f7f9fb !important;
  border-bottom: 1px solid #dfe7ef;
}

.background {
  padding: 0;
  overflow: hidden;
  min-height: 100vh;
  position: relative;
  max-height: 100vh;
  background: #F7F9FB;
  flex: 2 !important;

  .slideshow {
    width: 100%;
    height: 100%;
    display: block;
    position: relative;

    >ul {
      >li {
        width: 100%;
        height: 100%;
        display: none;
        animation: kenburns 30s linear, fadeIn 1.5s ease;
        transform: scale(1);
        position: absolute;
        top: 0;
        left: 0;

        &.active {
          display: block
        }

        &.active.last-active {
          display: none
        }

        img {
          object-fit: cover;
          object-position: center;
          width: 100%;
          height: 100%;
          display: block;
        }
      }
    }
  }
}


.preloader {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 50;
}

.preloader {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 50;

  div {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    background: linear-gradient(-45deg, #ee7752, #e73c7e, #23a6d5, #23d5ab);
    background-size: 400% 400%;
    animation: gradient 15s ease infinite;
    transform: scale(-1);
  }
}

.entry_box,
.image {
  min-height: 100vh;
  position: relative;
  max-height: 100vh;
}

.entry_box {
  padding: 0;
  overflow: hidden;
  border-right: 1px solid #dfe7ef;
  background: #fff;
}

@media (min-width: 1200px) {
  .col-xl-9 {
    flex: 0 0 auto;
    width: 75%;
  }
}

@media (min-width: 992px) {
  .col-lg-8 {
    flex: 0 0 auto;
    width: 66.66666667%;
  }
}

@media (max-width: 768px) {
  .home-nav {
    display: none;
  }

  .col-md-7 {
    flex: 0 0 auto;
    width: 58.33333333%;
  }

  .d-md-flex {
    display: flex !important;
  }

  .d-none {
    display: none !important;
  }

  .entry_box {
    flex: 2;
  }
}

@media only screen and (max-width: 1390px) and (min-width:1199px) {
  .col-xl-3 {
    &.entry_box {
      flex: 0 0 auto;
      width: 33.33333333%
    }
  }

  .col-xl-9.background {
    flex: 0 0 auto;
    width: 66.66666667%
  }
}

@keyframes kenburns {
  20% {
    opacity: 1;
    transform: scale(1.2) rotate(5deg)
  }

  40% {
    transform: scale(1)
  }
}

@media only screen and (max-width: 1390px) and (min-width: 1199px) {
  .col-xl-9.background {
    width: 66.66666667%;
  }
}

.log-col {
  width: 25%;
}

@media (max-width: 1200px) {
  .log-col {
    width: 30%;
  }
}

@media (max-width: 992px) {
  .log-col {
    width: 35%;
  }
}

@media (max-width: 885px) {
  .log-col {
    width: 40%;
  }
}

.row>* {
  flex-shrink: 0;
  width: 100%;
  max-width: 100%;
  padding-right: calc(var(--bs-gutter-x) * .5);
  padding-left: calc(var(--bs-gutter-x) * .5);
  margin-top: var(--bs-gutter-y);
}

.ant-form-item-row {
  text-align: left;
}

.login-bottom {
  padding: 0 20px;
  padding-top: 20px;
  padding-bottom: 20px;
  margin-top: 20px;
  text-align: center;
  background: #F7F9FB;
  border-top: 1px solid #DFE7EF;
  color: #919191;
  font-size: 14px;
}

#basic_username {
  height: 40px;
}

#basic_password {
  height: 30px;
}

.login-user-wrap {
  font-weight: 600;

  div {
    div {
      label {
        color: #808080 !important;
      }
    }
  }

}

.login-pw-wrap {
  font-weight: 600;

  div {
    div {
      label {
        color: #808080 !important;
      }
    }
  }
}

.login-title {
  color: #23676A;
  font-weight: 700;
  font-size: 20px;
  margin: 0;
  margin-bottom: 15px;
  text-align: left;
}

.login-flag {
  width: 20px;
  height: 20px;
  border-radius: 100%;
}

.login-bottom-text {
  p {
    margin-bottom: 5px;
  }
}

.login-lg {
  display: inline-block;
  color: #919191;
  padding: 5px 10px;
  background: #fff;
  border: 1px solid #DFE7EF;
  border-radius: 20px;
  cursor: pointer;
  position: relative;
  display: flex;
  width: 64px;
  justify-content: center;
  margin: 0 auto;
  margin-top: 10px;
}

.ant-checkbox-checked {
  &::after {
    border: none !important;
  }

  &:focus {
    border: none !important;
  }
}

.ant-checkbox-checked .ant-checkbox-inner {
  border-color: #E91E63 !important;
}

.ant-checkbox-wrapper {
  &:hover {
    .ant-checkbox-inner {
      border: 1px solid #d9d9d9 !important;
    }
  }
}
.ant-checkbox-wrapper-checked{
  &:hover {
    .ant-checkbox-inner {
      border: 1px solid #E91E63 !important;
    }
  }
}

// .checkbox {
//   &:hover {
//     .ant-checkbox-input {
//       background-color: transparent !important;
//       // 또는 다른 스타일을 지정하여 hover 효과를 원하는 대로 수정 가능
//     }
//   }
// }