/* Preview page styles */
.js-preview-view {
  display: flex;
  justify-content: center;
  align-items: center;
  padding-bottom: 16vh;
  height: 100vh;
}

.preview__root {
  flex-direction: column;
  height: fit-content;
}

.preview-wrap {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
}

.preview-video {
  // width: 800px;
  // height: 450px;
  width: 632px;
  height: 355px;
  background: #d9d9d9;
  margin: 1px 30px 1px 1px;
  border-radius: 14px;
  align-self: center;
}

.title-style {
  margin: 26px;
  padding-bottom: 20px;
}

.video-operations-preview div {
  // background-color: rgb(103, 103, 103) !important;
}

.video-operations-preview {

  // background-color: rgb(103, 103, 103) !important;
  button {
    background: #1890ff;
    width: 83px !important;
    height: 42px !important;
    border-radius: 6% !important;
    border: none;
    color: #fff;
  }
}

.join-button {
  color: rgba(255, 255, 255, 1);
  background: rgba(0, 141, 250, 1);
  border-radius: 14px;
  font-size: 16px;
  height: 3em;
  width: 24em;
  margin: 2em auto;
  cursor: pointer;
  border: none;
  font-stretch: expanded;
  transition: filter 0.15s ease-out;
}

.join-button:hover {
  filter: brightness(110%);
}

/* The "active" pseudo-class MUST come after "hover" */
.join-button:active {
  filter: brightness(80%);
}

.mic-feedback__very-low svg {
  color: green !important;
  opacity: 0.5 !important;
}

.mic-feedback__low svg {
  color: green !important;
  opacity: 0.6;
}

.mic-feedback__medium svg {
  color: green !important;
  opacity: 0.7;
}

.mic-feedback__high svg {
  color: green !important;
  opacity: 0.8;
}

.mic-feedback__very-high svg {
  color: green !important;
  opacity: 0.9;
}

.mic-feedback__max svg {
  color: green !important;
}

.audio-test {
  align-self: center;
}

.audio-test-wrap {
  // width: 800px;
  // margin: 0 auto;
  text-align: left;

  // h3{
  //   margin-top: 30px;
  // }
  .speaker-action {
    display: flex;

    .speaker-btn {
      width: 160px;
      margin-right: 30px;
    }

    .speaker-list {
      min-width: 300px;
    }
  }

  .speaker-output {
    // margin-top: 30px;
    margin-top: 20px;
    display: flex;

    .speaker-label {
      width: 160px;
      // margin-right: 30px;
    }
  }
}

.micBtnSvg {
  button {
    box-shadow: none !important;

    &:hover {
      background: none !important;
    }
  }
}

.cameraBtnSvg {
  button {
    box-shadow: none !important;

    &:hover {
      background: none !important;
    }
  }
}


@media screen and (max-width: 1241px) {
  .title-style {
    padding-bottom: 0px;
  }

  .preview-wrap {
    flex-direction: column;
  }

  .preview-video {
    margin: 0 0 30px 0;
  }
}

@media screen and (max-width: 1138px) {
  .preview-video {
    margin: 0 0 30px 0;
  }
}

@media screen and (max-width: 660px) {
  .js-preview-view {
    padding-bottom: 0;
    height: auto;
  }

  .container {
    width: 100%;
  }

  .preview-video {
    width: 90%;
    height: auto;
  }

  .audio-test-wrap .speaker-action {
    flex-flow: column;
  }

  .audio-test {
    width: 80%;
  }

  .speaker-btn {
    margin-bottom: 13px;
  }
}

.speaker-font-color {
  background-color: #1890ff;
}

.joinBtn {
  min-width: 40px;
  width: 83px !important;
  height: 42px !important;
  line-height: 50px;
  text-align: center;
  margin-right: min(30px, 1.5vw);
  cursor: pointer;

  svg {
    fill: #3f4a5b;
    width: 24px;
    height: 24px;

    &:hover {
      fill: #6571ff;
    }
  }
}

.outBtn {
  min-width: 40px;
  width: 83px !important;
  height: 42px !important;
  line-height: 50px;
  text-align: center;
  cursor: pointer;

  svg {
    fill: #3f4a5b;
    width: 24px;
    height: 24px;

    &:hover {
      fill: #6571ff;
    }
  }
}

//프리뷰 모달 preview modal
.preview-modal {
  .ant-modal-body {
    div {
      padding: 0;
    }
  }

  .js-preview-view {
    height: auto;

    .title-style {
      margin: 0;
      padding-bottom: 22px;
      text-align: center;
    }

    .preview-wrap {

      #js-preview-video {
        width: 497px;
        height: 282px;
        margin: 0;
      }

      .audio-test-wrap .speaker-output {
        margin-top: 8px;
      }

      .audio-test {
        margin-top: 24px;

        .audio-test-wrap {
          h3 {
            margin-bottom: 3px !important;
          }
        }
      }

      .video-footer {
        .microphone-footer {
          button {
            box-shadow: none;

            &:hover {
              background: none;
            }
          }
        }

        .camera-footer {
          button {
            box-shadow: none;

            &:hover {
              background: none;
            }
          }
        }

        .joinBtn {
          min-width: 40px;
          width: 83px !important;
          height: 42px !important;
          line-height: 50px;
          text-align: center;
          margin-right: min(30px, 1.5vw);
          cursor: pointer;

          svg {
            fill: #3f4a5b;
            width: 24px;
            height: 24px;

            &:hover {
              fill: #6571ff;
            }
          }
        }

        .outBtn {
          min-width: 40px;
          width: 83px !important;
          height: 42px !important;
          line-height: 50px;
          text-align: center;
          cursor: pointer;

          svg {
            fill: #3f4a5b;
            width: 24px;
            height: 24px;

            &:hover {
              fill: #6571ff;
            }
          }
        }

      }

      .speaker-action {
        button {
          line-height: 14px;

          span {
            font-size: 14px;
          }
        }

      }
    }
  }
}

.preview-modal {
  width: 800px !important;
  height: 600px;
  margin: 0 auto;
  top: 10% !important;
}

.preview-modal .ant-modal-content {
  width: 600px;
  height: 620px;
  margin: 0 auto;
}


@media screen and (max-width: 660px) {

  .preview-modal {
    width: 600px !important;
    height: 720px;
    margin: 0 auto;
    margin-top: -60px !important;
  }

  .preview-modal .ant-modal-content {
    width: 600px;
    height: 810px;
    margin: 0 auto;
  }
}

// @media screen and (max-width: 660px) {
//   .preview-modal {
//     .js-preview-view {
//       padding-bottom: 0;
//       height: auto;
//     }

//     .container {
//       width: 100%;
//     }

//     .preview-video {
//       width: 100%;
//       height: auto;
//     }

//     .audio-test-wrap .speaker-action {
//       flex-flow: column;
//     }

//     .audio-test {
//       width: 100%;
//     }

//     .speaker-btn {
//       margin-bottom: 13px;
//     }
//   }
// }

@media screen and (max-width: 628px) {

  .preview-modal {
    width: 500px !important;
    height: 720px;
    margin: 0 auto;
    margin-top: -60px !important;
  }

  .preview-modal .ant-modal-content {
    width: 500px;
    height: 700px;
    margin: 0 auto;
    padding: 35px;
  }
}

@media screen and (max-width: 600px) {
  .preview-modal .js-preview-view .preview-wrap .video-footer .joinBtn svg {
    fill: #3f4a5b;
    width: 19px;
    height: 19px;
  }

  .preview-modal .js-preview-view .preview-wrap .video-footer .outBtn svg {
    fill: #3f4a5b;
    width: 19px;
    height: 19px;
  }
}


@media screen and (max-width: 524px) {

  .preview-modal {
    width: 300px !important;
    height: 100%;
    margin: 0 auto;
    margin-top: -60px !important;

    .js-preview-view {
      .preview-wrap {
        .audio-test {
          width: 90% !important;
          margin-top: 30px;
        }
      }
    }
  }

  .preview-modal .ant-modal-content {
    width: 300px;
    height: 550px;
    margin: 0 auto;
    padding-top: 30px;
    margin: 3rem 0 0 0;
  }

  .preview-modal .js-preview-view .preview-wrap #js-preview-video {
    width: 265px;
    height: 150px;
    margin: 0;
  }

  .audio-test-wrap .speaker-action .speaker-list {
    max-width: 214px !important;
  }

  .preview-footer {
    display: flex;

    .video-footer {
      .microphone-footer {
        button {
          width: 30px !important;
        }
      }

      .camera-footer {
        button {
          width: 30px !important;
        }
      }
    }
  }

  .preview-modal {
    .js-preview-view {
      .preview-wrap {
        .video-footer {
          .outBtn {
            width: 30px !important;
          }

          .joinBtn {
            width: 30px !important;
          }
        }
      }
    }
  }

  .audio-test-wrap {
    .speaker-action {
      .speaker-list {
        min-width: 100px;
      }
    }
  }
}

@media screen and (max-width: 360px) {
  .preview-modal .ant-modal-content {
    margin: 0 auto !important;
  }
}

@media screen and (max-width: 330px) {

  .preview-modal {
    width: 260px !important;
    height: 100%;
    margin: 0 auto;
    margin-top: -60px !important;
  }

  .preview-modal .ant-modal-content {
    width: 260px;
    height: 550px;
    margin: 0 auto;
  }

  .preview-modal .js-preview-view .preview-wrap #js-preview-video {
    width: 235px;
    height: 150px;
    margin: 0;
  }

  .audio-test {
    width: 90% !important;
    margin-top: 5px;
  }

  .ant-modal-content {
    width: 260px !important;
  }
}